const currencyGbp = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});

export const padZero = (str, padZeros=2) => {
    // str = String(str);
    // return str.length<2 ? `0${str}` : str;
    return Number(str).toString().padStart(padZeros, '0');
}

export const sleep = (ms=300) => {
    return new Promise((resolve)=>{
        setTimeout(()=>{ resolve(); }, ms);
    });
}

export const getHostname = () => {
    if(typeof window !== "undefined") {
        return String(window.location.host).split(':')[0];
    }
}

export const openBase64 = (base64, type='application/pdf') => {
    const blob = base64ToBlob( base64, type);
    const url = URL.createObjectURL( blob );
    const pdfWindow = window.open();
    pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
}

export const openHtml = (html) => {
    const newWindow = window.open();
    newWindow.document.write(html);
}

export const base64ToBlob = ( base64, type = "application/octet-stream" ) => {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
}

export const getKeywords = (obj, excludeKeys) => {
    excludeKeys = excludeKeys || [];
    if(excludeKeys) {
        obj = copyObject(obj);
    }
    for(const key of excludeKeys) {
        delete obj[key];
    }
    return Object.values(obj).join(' ').toLowerCase();
}

export const copyObject = (obj) => {
    obj = toObject(obj);
    return JSON.parse(JSON.stringify(obj));
}

export const isLocalhost = () => {
    const host = getHostname();
    return host ? (host.startsWith('localhost') || host.includes('192.168.')) : false;
}

export const uuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const toBoolean = (value, defaultValue) => {
    defaultValue = defaultValue === true ? true : false;
    if(value===true) {
        return true;
    }
    if(value===false) {
        return false;
    }
    return defaultValue;
}

export const toObject = (obj) => toJson(obj);
export const toJson = (obj) => {
    if(obj && typeof obj === 'object') {
        return obj;
    }

    try {
        obj = JSON.parse(obj);
    }
    catch(error) {
        obj = {};
    }

    if(!obj) {
        obj = {};
    }

    return obj;
}

export const toNumber = (value) => {
    return Number(String(value).replace(/[^\d.-]/g, ''));
}

export const isNullish = (value) => {
    return value === undefined || value === null;
}

export const isObject = (a) => {
    return (!!a) && (a.constructor === Object);
};

export const toArray = (arr) => {
    return Array.isArray(arr) ? arr : [];
}

export const toCurrency = (value) => {
    const num = toNumber(value);
    return currencyGbp.format(num);
}

export const getFields = (fields) => {
    if(!Array.isArray(fields)) {
        fields = [];
    }
    
    const data = [];
    for(const field of fields) {
        data.push(getField(field));
    }
    return data;
}

export const getField = (field) => {
    if(typeof field === 'string') {
        field = {type:'text', label: textToTitle(field), key:field};
    }
    if(!field.type) {
        field.type = 'text';
    }
    if(field.type === 'select') {
        field.opts = Array.isArray(field.opts) ? field.opts : [];
    }

    return field;
}

export const textToTitle = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const renderValue = (value, type) => {
    if(type) {
        switch(type) {
        case 'currency':
            return toCurrency(value);
        case 'number':
            return toNumber(value);
        }
    }
    
    switch (typeof value) {
        case 'boolean':
            return value === true ? 'true' : 'false';
        case 'object':
            if(typeof value?.$$typeof === 'symbol') {
                return value;
            }
            return value ? JSON.stringify(value) : '';
        case 'string':
            return value || '';
        default:
            return value;
    }
}

export const setWindowTitle = (title) => {
    if(typeof window !== "undefined") {
        window.document.title = title;
    }
}

export const isEmptyObject = (obj) => {
    if(!obj || obj === null || Object.keys(obj).length === 0) { return true; }
    return false;
}

export const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
}

export const yesNoBoolean = (value) => {
    if(['true', true].includes(value)) { return 'yes'; }
    if(['false', false].includes(value)) { return 'no'; }
    if(['no', 'NO'].includes(value)) { return false; }
    return true;

}

const utils = {
    toCurrency,
    isEmptyObject,
    toArray,
    getHostname,
    getKeywords,
    isLocalhost,
    uuid,
    isObject,
    isNullish,
    toBoolean,
    toNumber,
    toJson,
    toObject,
    textToTitle,
    getFields,
    getField,
    renderValue,
    copyObject,
    setWindowTitle,
    copyToClipboard,
    openBase64,
    openHtml,
    base64ToBlob,
    padZero,
    yesNoBoolean,
    sleep,
}

export default utils;