
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Badge, Card, Row, Col, Breadcrumb } from 'react-bootstrap';

import { toast } from 'sonner';

import Btn from 'ui/btn';
import Modal from 'ui/modal';
import Input from 'ui/input';

import api from 'core/api';
import utils from 'core/utils';
import dates from 'core/dates';
import fyi from 'core/fyi';

const VIEW_PATH = 'app';
const ROOT_PARENT_ID = 'ROOT';

const rootTopic = {title:'ROOT', id: ROOT_PARENT_ID, children:[]};

export default function AppView() {

    const navigate = useNavigate();
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [topics, setTopics] = useState(rootTopic);
    const [topic, setTopic] = useState(rootTopic);
    const [data, setData] = useState({});
    const [working, setWorking] = useState(false);

    const init = async(reload) => {
        // console.log('init', {reload});
        let data = await loadTopics(reload);
        const pathname = location.pathname;
        const paths = pathname.split('/') || [];
        const breadcrumbs = [{title:'Topics', path:''}];
        let topic = {...data};
        for(const path of paths) {
            if(!path || path === VIEW_PATH) {
                continue;
            }

            const found = topic.children.find(t=>t.id===path);
            if(found) {
                breadcrumbs.push({title:found.title, path:`${found.path}/${found.id}`});
                topic = found;
            }
        }

        setBreadcrumbs(breadcrumbs);
        await loadTopic(topic);
    }

    const loadTopics = async(reload) => {
        if(reload || topics.children.length===0) {
            setWorking(true);
            try {
                let data = await api.getRequest('/topics');
                data = {...rootTopic, children:data};
                setTopics(data);
                setWorking(false);
                return data;
            }
            catch(error) {
                setWorking(false);
                window.alert(JSON.stringify(error));
            }   
        }
        return topics;
    }

    const loadTopic = async(topic) => {
        setTopic(topic);
        if(topic.id === ROOT_PARENT_ID) { return; }
        setWorking(true);
        try {
            const d = await api.getRequest(`/topics/${topic.id}`);
            setData(d);
            setWorking(false);
            return topic;
        }
        catch(error) {
            setWorking(false);
            window.alert(JSON.stringify(error));
        }   
    }

    const onNav = (path) => {
        setData({});
        navigate(`/${VIEW_PATH}${path}`);
        // setPath(path);
    }

    // useEffect(()=>{ init(); }, []);
    useEffect(()=>{ init(); }, [location, topics]);

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    return (
        <>
            <div className={classes.join(' ')}>
                <div className='app-view'>
                <div className='float-end'><Btn variant="secondary-outline" iconName={'reload'} onClick={async()=>{ await loadTopics(true); }} /></div>
                <Breadcrumb>
                    {breadcrumbs.map((bc, i)=>(<Breadcrumb.Item key={i} onClick={(e)=>{ e.preventDefault(); onNav(bc.path); }} href={bc.path}>{bc.title}</Breadcrumb.Item>))}
                </Breadcrumb>
                {topic.children.map((t, i)=>(
                    <div key={i}>
                        <a href="/topic" className='topic-title' onClick={(e)=>{ e.preventDefault(); onNav(t.path+'/'+t.id); }}>{t.title}</a> 
                    </div>
                ))}
                {data.html && <div className='topic-html'>
                    <div dangerouslySetInnerHTML={{__html: data.html}}></div>
                </div>}
                </div>
            </div>
        </>
    );

}