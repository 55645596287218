import { fetchAuthSession } from 'aws-amplify/auth';

const apiUrl = 'https://afgjpdw2r1.execute-api.eu-west-2.amazonaws.com/v1';

const getHeaders = async() => {
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

    return {
        'Content-Type': 'application/json',
        Authorization: authToken,
    };
}

const makeRequest = async(url, opts={}) => {
    const headers = await getHeaders();
    opts.headers = opts.headers || {};
    opts.headers = {
        ...headers,
        ...opts.headers,
    };

    try {
        const res = await fetch(url, opts);
        const json = await res.json();
        if(json?.error) {
            throw new Error(json.error);
        }
        return json;
    }
    catch(error) {
        console.log(error);
        throw new Error(error);
    }
}

const isFileData = (data) => {
    return ['File', 'FormData'].includes(data.constructor.name);
}

export const getRequest = async(url) => {
    return await makeRequest(`${apiUrl}${url}`);
}

export const putRequest = async(url, data={}, headers={}) => {
    return await makeRequest(`${apiUrl}${url}`, {method: 'PUT', body: isFileData(data) ? data : JSON.stringify(data), headers});
}

export const postRequest = async(url, data={}, headers={}) => {
    return await makeRequest(`${apiUrl}${url}`, {method: 'POST', body: isFileData(data) ? data : JSON.stringify(data), headers});
}

export const deleteRequest = async(url) => {
    return await makeRequest(`${apiUrl}${url}`, {method: 'DELETE'});
}

const api = {
    getRequest,
    putRequest,
    postRequest,
    deleteRequest,
}

export default api;
