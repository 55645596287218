import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Card, Row, Col, Tabs, Tab } from 'react-bootstrap';

import Editor from 'ui/editor';

import niceData from './nice-data';

const processPasteList = (dom) => {
    const ps = Array.from(dom.querySelectorAll(':scope > p'));
    dom.querySelectorAll('a').forEach(e=>{
        e.replaceWith(document.createTextNode(e.textContent));
    });

    const data = {};
    let key;
    for(const p of ps) {
        const spans = Array.from(p.querySelectorAll(':scope > span'));
        const span = spans[0];
        if(span) {
            key = String(span.innerText).trim();
            p.removeChild(span);
        }

        if(key) {
            if(!data[key]) { data[key] = []; }

            data[key].push(String(p.innerText).trim());

            if(p.parentNode) {
                const ul = p.parentNode.querySelector('ul');
                if(ul) {
                    ul.className = '';
                    for(const li of Array.from(ul.querySelectorAll('li'))) { li.classList.remove(...li.classList); }
                    for(const p of Array.from(ul.querySelectorAll('p'))) { p.classList.remove(...p.classList); }
                    data[key].push(ul.outerHTML);
                }
            }

        }

    }
    let html = '<ul>';
    for(const num in data) {
        html += `<li>${num} - ${data[num].join(' ')}</li>`;
    }
    html = html+'</ul>';
    html = html.replaceAll(' class=""', '');
    return html;
}

const processPaste = (html) => {
    const dom = document.createElement('div');
    dom.innerHTML = html;
    
    const p = dom.querySelector('p');
    if(p && p.querySelector('span')) {  
        return processPasteList(dom);
    }

    return html;
}

const editorConfig = {
    height: 500,
    toolbar: 'undo redo | bold italic underline | backcolor forecolor | styles | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent removeformat | numlist bullist checklist | ' +
        'table image code | toText',
    menubar: false,
    plugins: [
        'table', 'image', 'code',
    ],
    setup: function (editor) {
        editor.ui.registry.addButton('toText', {
            text: 'To Text',
            onAction: function (_) {
            //   editor.insertContent('&nbsp;<strong>It\'s my button!</strong>&nbsp;');
                const content = editor.selection.getContent();
                const div = document.createElement('div');
                div.innerHTML = content;
                editor.setContent(div.innerText);
            },
        });
    },
    // paste_preprocess: function(plugin, args) { args.content = processPaste(args.content); },
    // paste_preprocess: function(plugin, args) { console.log(args); },
    // paste_as_text:true,
    // link_list: [
    //     { title: 'My page 1', value: 'https://www.tiny.cloud' },
    //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
    // link_list: async(resolve) => {
    //     const links = await api.getRequest(`/editor-links`);
    //     resolve(links);
    // },
    // image_list: async(resolve) => {
    //     const files = await api.getRequest(`/files`);
    //     const opts = files.map(f=>({title:f.filename, value:f.url}));
    //     resolve(opts);
    // }
    // image_list: [
    //     { title: 'My page 1', value: 'https://www.tiny.cloud' },
    //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
};

export default function NiceView() {

    const [value, setValue] = useState('');

    const init = async() => {
        const dom = document.createElement('div');
        dom.innerHTML = niceData.html;

        const content = dom.querySelector('.content');
        const html = [];
        const sections = Array.from(content.querySelectorAll('.chapter > .section'));
        console.log('found sections', sections.length);

        for(const section of sections) {
            const h3 = section.querySelector('h3');
            if(h3) {
                html.push(`<h3>${h3.innerText}</h3>`);
            }

            const subSections = Array.from(section.querySelectorAll('.section'));
            if(subSections.length>0) {
                for(const subSection of subSections) {
                    const h4 = subSection.querySelector('h4');
                    if(h4) {
                        html.push(`<h4>${h4.innerText}</h4>`);
                    }

                    const recs = Array.from(subSection.querySelectorAll('.recommendation_text'));
                    const recs2 = Array.from(subSection.querySelectorAll('.recommendation'));
                    if(recs.length>0) {
                        for(const rec of recs) {
                            const listHtml = processPasteList(rec);
                            html.push(listHtml);
                        }
                    }
                    else if(recs2.length>0) {
                        for(const rec of recs2) {
                            const listHtml = processPasteList(rec);
                            html.push(listHtml);
                        }
                    }
                    else {
                        const listHtml = processPasteList(subSection);
                        html.push(listHtml);
                    }
                }
            }
            else {
                const divs = Array.from(section.querySelectorAll('div'));
                if(divs.length>0) {
                    for(const div of divs) {
                        const listHtml = processPasteList(div);
                        html.push(listHtml);
                    }
                }
                else {
                    const listHtml = processPasteList(section);
                    html.push(listHtml);
                }
            }
        }
        
        setValue(html.join(' '));
    }

    useEffect(()=>{ init(); }, []);

    return (
        <Card>
        <Card.Header>NICE Topic Editor</Card.Header>
        <Card.Body>
            <Editor config={editorConfig} value={value} onChange={setValue} />
        </Card.Body>
        </Card>
    );
}