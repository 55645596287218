
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Badge, Card, Row, Col } from 'react-bootstrap';

import { toast } from 'sonner';

import Btn from 'ui/btn';
import Modal from 'ui/modal';
import Input from 'ui/input';

import api from 'core/api';
import utils from 'core/utils';
import dates from 'core/dates';
import fyi from 'core/fyi';

const ROOT_PARENT_ID = 'ROOT';

export default function TopicsTreeView() {

    const navigate = useNavigate();
    const [topics, setTopics] = useState([]);
    const [topicOpts, setTopicOpts] = useState([]);
    const [working, setWorking] = useState(false);
    const [showAddTopic, setShowAddTopic] = useState(false);
    const [addTopic, setAddTopic] = useState({});
    const [filter, setFilter] = useState('');

    const init = async() => {
        await loadTopics();
    }

    const loadTopics = async(reload) => {
        if(reload || topics.length===0) {
            setWorking(true);
            let topics = [];
            try {
                topics = await api.getRequest('/topics');
                setWorking(false);
                // return data;
            }
            catch(error) {
                setWorking(false);
                window.alert(JSON.stringify(error));
            }
            topics = fyi.flattenTopics(topics);

            const topicOpts = [{value:ROOT_PARENT_ID, text:'ROOT'}];
            for(const i in topics) {
                const topic = topics[i];
                topicOpts.push({value:topic.id, text:`${'--'.repeat(topic.level)} ${topic.title}`});
                topics[i].updatedAt = dates.createDateTimeClass(topic.updatedAt).label();
                topics[i].keywords = utils.getKeywords(topic);
            }
            setTopics(topics);
            setTopicOpts(topicOpts);
        }
        // return data;
    }

    const onAddTopic = async() => {
        if(!window.confirm('Add Topic?')) { return; }

        let parentId = addTopic.parentId;
        let sequence = 0;
        if(!parentId) { 
            parentId = ROOT_PARENT_ID;
            const children = topics.filter(t=>t.parentId===parentId);
            sequence = children.length;
        }
        else {
            const parentTopic = topics.find(t=>t.id===parentId);
            if(!parentTopic) { window.alert(`Could not find parent Topic with id '${parentId}'`); return; }
            sequence = parentTopic.children.length;
        }

        const data = {
            ...addTopic,
            parentId,
            sequence,
        };
        
        setWorking(true);
        try {
            await api.postRequest('/topics', data);
        }
        catch(error) {
            setWorking(false);
            toast(error.message);
            await loadTopics(true);
            return;
        }
        
        setWorking(false);

        await loadTopics(true);
        setShowAddTopic(false);

        toast('Topic added');
    }

    const moveTopic = async(topicId, sequence) => {
        setWorking(true);
        try {
            await api.putRequest(`/topics/${topicId}/sequence`, {sequence});
        }
        catch(error) {
            setWorking(false);
            toast(error.message);
            await loadTopics(true);
            return;
        }
        
        setWorking(false);
        await loadTopics(true);
    }

    const handleSetFilter = (value) => {
        setFilter(value);
    }

    const getFilteredTopics = () => {

        const q = String(filter).trim().toLowerCase();
        if(!q) {
            return topics;
        }
        return topics.filter(c=>c.keywords.includes(q));
    }

    useEffect(()=>{ init(); }, []);

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    const filteredTopics = getFilteredTopics();

    return (
        <>
            <Modal show={showAddTopic} title={`Add Topic`} onHide={()=>{ setShowAddTopic(false); }}>
                {showAddTopic && <>
                    Parent<br />
                    <Input.Select opts={topicOpts} value={addTopic.parentId} onChange={(parentId)=>{ setAddTopic({...addTopic, parentId}); }} />
                    <br />
                    Title<br />
                    <Input.Text value={addTopic.title} onChange={(title)=>{ setAddTopic({...addTopic, title}); }} />
                    <br /><br />
                    
                    <Btn text="Add Topic" onClick={onAddTopic} working={working} />
                </>}
            </Modal>
            <Card className='drop-shadow'>
            <Card.Header>
                <div className='float-end'>
                    <Btn text="Reload Topics" onClick={async()=>{ await loadTopics(true); }} working={working} />{' '}
                    <Btn text="Add Topic" variant="primary" onClick={()=>{ setShowAddTopic(true); }} working={working} />{' '}
                </div>
                Topics ({filteredTopics.length})
            </Card.Header>
            <Card.Body>
                <Row>
                <Col sm="7">
                    <Input.Text value={filter} onChange={handleSetFilter} placeholder={'Filter Topics'} />
                </Col>
                <Col sm="5">
                    
                </Col>
                </Row>
                <div className={classes.join(' ')}>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Sequence</th><th>Title</th><th>Updated At</th><th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredTopics.map((topic, i)=>{
                        return (
                            <tr key={i}>
                                <td>
                                    <Btn iconName={'ArrowUp'} text="" size="sm" onClick={async()=>{ await moveTopic(topic.id, topic.sequence-1); }} />{' '}
                                    <Btn iconName={'ArrowDown'} text="" size="sm" onClick={async()=>{ await moveTopic(topic.id, topic.sequence+1); }} />
                                </td>
                                <td>{`${'--'.repeat(topic.level-1)} ${topic.title}`}{' '}{topic.published && <Badge bg="secondary">published</Badge>}</td>
                                <td>{topic.updatedAt}</td>
                                <td><Btn text="Edit" onClick={()=>{ navigate(`/topic/${topic.id}`); }} /></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
            </Card.Body>
            </Card>
        </>
    );

}