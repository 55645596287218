import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Card, Row, Col, Tabs, Tab } from 'react-bootstrap';

import { useDropzone } from 'react-dropzone';

// import 'tinymce/tinymce';
// import 'tinymce/icons/default/icons';
// import 'tinymce/themes/silver/theme';
// import 'tinymce/skins/ui/oxide/skin.min.css';
// import 'tinymce/models/dom/model';

// // import 'tinymce/plugins/link/plugin';
// import 'tinymce/plugins/table/plugin';
// import 'tinymce/plugins/image/plugin';

// import { Editor } from '@tinymce/tinymce-react';

import utils from 'core/utils';
import api from 'core/api';
import dates from 'core/dates';

import { toast } from 'sonner';

import Btn from 'ui/btn';
import Modal from 'ui/modal';
import Input from 'ui/input';
import Editor from 'ui/editor';

const publishedOpts = [
    {value:'no', text:'No'},
    {value:'yes', text:'Yes'},
];

const ROOT_PARENT_ID = 'ROOT';

const editorConfig = {
    height: 500,
    toolbar: 'undo redo | bold italic underline | backcolor forecolor | superscript subscript | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent removeformat | numlist bullist checklist | ' +
        'table image code',
    menubar: false,
    plugins: [
        'table', 'image', 'code', 'lists',
    ],
    // link_list: [
    //     { title: 'My page 1', value: 'https://www.tiny.cloud' },
    //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
    // link_list: async(resolve) => {
    //     const links = await api.getRequest(`/editor-links`);
    //     resolve(links);
    // },
    image_list: async(resolve) => {
        const files = await api.getRequest(`/files`);
        const opts = files.map(f=>({title:f.filename, value:f.url}));
        resolve(opts);
    }
    // image_list: [
    //     { title: 'My page 1', value: 'https://www.tiny.cloud' },
    //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
};

const FileBrowser = () => {

    const [files, setFiles] = useState([]);
    const [working, setWorking] = useState(false);
    const [filter, setFilter] = useState('');
    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragActive, isDragAccept, isDragReject } = useDropzone();

    const init = async() => {
        await loadFiles();
    }

    const loadFiles = async() => {
        setWorking(true);
        try {
            const files = await api.getRequest('/files');
            for(const i in files) {
                files[i]['keywords'] = files[i]['filename'].toLowerCase();
            }
            setFiles(files);
        }
        catch(error) {
            toast(error.message);
        }

        setWorking(false);
    }

    const uploadFiles = async() => {
        
        // const reader = new FileReader();

        for(const file of acceptedFiles) {
            // const formData = new FormData();
            // formData.append('file', file, file.name);

            const reader = new FileReader();
            reader.addEventListener('load', async()=>{  
                setWorking(true);

                try {
                    await api.postRequest(`/files`, {filename:file.name, content:reader.result});
                    toast(`File '${file.name}' uploaded`);
                }
                catch(error) {
                    toast(error.message);
                }

                setWorking(false);
                await loadFiles();

            }, false);

            reader.readAsDataURL(file);
            // try {
            //     await api.postRequest(`/files`, formData);
            //     toast(`File '${file.name}' uploaded`);
            // }
            // catch(error) {
            //     toast(error.message);
            // }
        }
    }

    const deleteFile = async(file) => {
        if(!window.confirm(`Delete '${file.filename}'?`)) { return; }
        setWorking(true);
        try {
            await api.deleteRequest(`/files/${file.filename}`);
            toast(`File '${file.filename}' deleted`);
        }
        catch(error) {
            toast(error.message);
        }
        setWorking(false);
        await loadFiles();
    }

    const getFilteredFiles = () => {

        const q = String(filter).trim().toLowerCase();
        if(!q) {
            return files;
        }
        return files.filter(c=>c.keywords.includes(q));
    }

    useEffect(()=>{ init(); }, []);
    useEffect(()=>{ uploadFiles(); }, [acceptedFiles]);

    // const filelis = acceptedFiles.map(file => (
    //     <li key={file.path}>
    //       {file.path} - {file.size} bytes
    //     </li>
    //   ));

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    const filteredFiles = getFilteredFiles();

    const dropzoneClasses = ['dropzone'];
    // console.log({isFocused, isDragActive});
    if(isDragActive) {
        dropzoneClasses.push('dropzone-active');
    }

    return (
        <div className={classes.join(' ')}>
            <div style={{position:'relative'}}>
                <div style={{position:'absolute', top:-60, left: 100, width:500}}>
                <Row>
                <Col sm="6">
                    <Input.Text value={filter} onChange={setFilter} placeholder={'Filter Files'} />
                </Col>
                <Col sm="6">
                    <Btn text='Reload Files' onClick={loadFiles} />
                </Col>
                </Row>
            </div>
            </div>
            <div {...getRootProps({className: dropzoneClasses.join(' ')})}>
                <input {...getInputProps()} />
                <p>Drag / drop files here, or click to select files</p>
            </div>

            <br /><br />

            <table className='table table-striped'>
                <tbody>
                {filteredFiles.map((file, i)=>(
                    <tr key={i}>
                        <td><a href={file.url} target='_blank' rel="noreferrer"><img src={file.url} alt={file.filename} style={{maxWidth:100, maxHeight:100}} /></a></td>
                        <td>{file.filename}</td>
                        <td>{file.size}</td>
                        <td>{file.type}</td>
                        <td>
                            <Btn text="X" variant={'danger'} onClick={async()=>{ await deleteFile(file); }} />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default function TopicView() {

    const navigate = useNavigate();
    const { topicId } = useParams();
    const [working, setWorking] = useState(false);
    const [topic, setTopic] = useState({title:'', html:''});
    const [parentOpts, setParentOpts] = useState([]);
    const [showFileBrowser, setShowFileBrowser] = useState(false);
    // const [topicHtml, setTopicHtml] = useState('');

    // console.log(topic);

    const init = async() => {
        setWorking(true);
        try {
            const data = await api.getRequest(`/topics/${topicId}`);
            processSetTopic(data);
            const topics = await api.getRequest(`/topics`);
            let opts = processParentOpts(topics);
            opts.unshift({value:ROOT_PARENT_ID, text:'ROOT'});
            opts = opts.filter(opt=>opt.value !== topicId);
            setParentOpts(opts);
        }
        catch(error) {
            toast.error(error.message);
        }   
        
        setWorking(false);
    }

    const processParentOpts = (topics, level=1) => {
        level = level || 1;
        const opts = [];
        for(const topic of topics) {
            const text = `${'--'.repeat(level)} ${topic.title}`;
            opts.push({value:topic.id, text});
            const childs = processParentOpts(topic.children, level+1);
            for(const child of childs) {
                opts.push(child);
            }
        }
        return opts;
    }

    const processSetTopic = (data) => {
        data.published = utils.yesNoBoolean(data.published);
        data.updatedAt = dates.createDateTimeClass(data.updatedAt).label();
        data.createdAt = dates.createDateTimeClass(data.createdAt).label();
        setTopic(data);
    }

    const saveTopic = async() => {
        if(!window.confirm('Update Topic?')) { return; }

        setWorking(true);
        const data = {
            ...topic,
        };

        if(!data.title) {
            window.alert('Topic missing Title');
            return;
        }

        data.published = utils.yesNoBoolean(data.published);

        try {
            const updatedData = await api.putRequest(`/topics/${topicId}`, data);
            processSetTopic(updatedData);
        }
        catch(error) {
            setWorking(false);
            toast.error(error.message);
            return;
        }

        setWorking(false);
        toast('Topic updated');
    }

    const removeTopic = async() => {
        if(!window.confirm('Remove Topic?')) { return; }
        setWorking(true);
        try {
            await api.deleteRequest(`/topics/${topicId}`);
        }
        catch(error) {
            toast.error(error.message);
            setWorking(false);
            return;
        }
        navigate('/topics');
        toast('Topic Removed');
        setWorking(false);
    }

    const setTopicValue = (key, value) => { setTopic({...topic, [key]:value}); }

    useEffect(()=>{ init(); }, [topicId]);

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    return (
        <>
            <Modal show={showFileBrowser} size={'xl'} title="Files" onHide={()=>{ setShowFileBrowser(false); }}>
                {showFileBrowser && <>
                    <FileBrowser />
                </>}
                <br />
                <Btn text={'Close'} onClick={()=>{ setShowFileBrowser(false); }} />
            </Modal>
            <div className={classes.join(' ')}>
                <Card>
                    <Card.Header>
                        <div className='float-end'>
                            <Btn text="File Browser" variant="info" onClick={()=>{ setShowFileBrowser(true); }} />{' '}
                            <Btn text="Back to Topics" onClick={()=>{ navigate('/topics'); }} />{' '}
                            <Btn text="Update Topic" variant="primary" onClick={saveTopic} working={working} />
                        </div>
                        {/* <Breadcrumb>
                            <Breadcrumb.Item onClick={(e)=>{ e.preventDefault(); navigate(-1); }} href={''}>Topics</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={(e)=>{ e.preventDefault(); }} href={''}>{topic.title}</Breadcrumb.Item>
                        </Breadcrumb> */}
                        Topic '{topic.title}'
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm="9">
                            Title 
                            <br />
                            <Input.Text  value={topic.title} onChange={(title)=>{ setTopicValue('title', title); }} />
                            <br />
                            Parent 
                            <br />
                            <Input.Select opts={parentOpts} value={topic.parentId} onChange={(parentId)=>{ setTopicValue('parentId', parentId); }} />
                            <br />
                            Content 
                            <br />
                            {/* <Editor init={{}} tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'} value={topic.html} onEditorChange={(html)=>{ setTopicValue('html', html); }} /> */}
                            {/* <Editor init={editorConfig} value={topic.html} onEditorChange={(html)=>{ setTopicValue('html', html); }} /> */}
                            <Editor config={editorConfig} value={topic.html} onChange={(html)=>{ setTopicValue('html', html); }} />
                            </Col>
                            <Col sm="3">
                                <Card>
                                    <Card.Header>Properties</Card.Header>
                                    <Card.Body>
                                        Created At
                                        <br />
                                        {topic.createdAt}
                                        <br /><br />
                                        Updated At
                                        <br />
                                        {topic.updatedAt}
                                        <br /><br />
                                        Published 
                                        <br />
                                        <Input.Select opts={publishedOpts} value={topic.published} onChange={(published)=>{ setTopicValue('published', published); }} />
                                        <br />
                                        Icon 
                                        <br />
                                        <Input.Text  value={topic.appIcon} onChange={(appIcon)=>{ setTopicValue('appIcon', appIcon); }} />
                                        <br />
                                        Icon Colour
                                        <br />
                                        <Input.Colour  value={topic.iconColour} onChange={(iconColour)=>{ setTopicValue('iconColour', iconColour); }} />
                                        <br />
                                        <hr />
                                        <Btn text="Remove Topic" variant="danger" onClick={removeTopic} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );

}