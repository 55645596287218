import { signUp as authSignUp, confirmSignUp as authConfirmSignUp, signIn as authSignIn } from 'aws-amplify/auth';
import { Alert } from 'react-bootstrap';
import { useState } from 'react';

import Input from 'ui/input';
import Btn from 'ui/btn';

import utils from 'core/utils';

export default function SignUp({fields}) {

    fields = utils.toArray(fields);

    const defaultValues = {email:'', password:'', confirmPassword:'', code:''};
    for(const field of fields) {
        defaultValues[field.key] = field?.value || '';
    }

    const standardKeys = ['email', 'password', 'confirmPassword', 'code'];
    const nonCustomAttrs = ['phone_number'];

    const [working, setWorking] = useState(false);
    const [message, setMessage] = useState('');
    const [values, setValues] = useState(defaultValues);
    const [confirmation, setConfirmation] = useState(null);

    const resetValues = () => {
        const rvalues = {};
        for(const field of fields) {
            rvalues[field.key] = field?.value || '';
        }
        for(const key of standardKeys) {
            rvalues[key] = '';
        }

        setValues({...values, ...rvalues});
    }

    const setValue = (key, value) => {
        setValues({...values, [key]:value});
    }

    const canSignUp = () => {
        return values.email && values.password && values.confirmPassword;
    }

    const onSignUp = async() => {

        setMessage('');
        if(!values.password || !values.confirmPassword) {
            setMessage('Password or Confirm Password missing');
            return;
        }
        if(values.password !== values.confirmPassword) {
            setMessage('Password and Confirm Password must match');
            return;
        }

        setWorking(true);

        const attrs = {};
        for(const field of fields) {
            const key = field.key;
            if(standardKeys.includes(key)) {
                continue;
            }
            if(nonCustomAttrs.includes(key)) {
                attrs[key] = values[key];
            }
            else {
                attrs[`custom:${key}`] = values[key];
            }
        }

        let res;
        try {
            res = await authSignUp({username:String(values.email).toLowerCase(), password:values.password, attributes:attrs});
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        console.log(res);

        if(res?.UserConfirmed) {
            setWorking(false);
            return;
        }

        if(!res?.codeDeliveryDetails) {
            setMessage('Thanks for signing up, we will notify you when your account has been confirmed.');
            resetValues();
        }
        else {
            setConfirmation(res.codeDeliveryDetails);
        }

        setWorking(false);
    }

    const onConfirm = async() => {
        setWorking(true);

        let res;
        try {
            res = await authConfirmSignUp(values.email, values.code);
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        try {
            await authSignIn(values.email, values.password);
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        setWorking(false);
    }

    const onBack = () => {
        setConfirmation(null);
    }

    if(confirmation) {
        return (<>
            Confirmation Code
            <Input.Text placeholder="eg 135790" value={values.code} onChange={(value)=>{ setValue('code', value); }} autoFocus />
            <br />
            <Btn text="Confirm" onClick={onConfirm} working={working} /> <Btn variant={'link'} text="back to Sign Up" onClick={onBack} />
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>);
    }

    return (
        <>
            Email
            <Input.Text placeholder="email@domain.com" value={values.email} onChange={(value)=>{ setValue('email', value); }} autoFocus autoCapitalize="off" />
            <br />
            Create Password
            <Input.Password value={values.password} onChange={(value)=>{ setValue('password', value); }} />
            {/* <small>should be minimum 8 characters, containing 1 number and 1 uppercase</small> */}
            <br />
            Confirm Password
            <Input.Password value={values.confirmPassword} onChange={(value)=>{ setValue('confirmPassword', value); }} />
            <br />
            {fields.length > 0 && <hr />}
            {fields.map((field, i)=>(
                <span key={i}>
                {field.type !== 'hidden' && (<>{field.label}</>)}
                {field.type === 'text' && <Input.Text value={values[field.key]} onChange={(value)=>{ setValue(field.key, value); }} />}
                {field.type === 'select' && <Input.Select value={values[field.key]} opts={field.opts} onChange={(value)=>{ setValue(field.key, value); }} />}
                {field.type === 'hidden' && <Input.Hidden value={values[field.key]} onChange={(value)=>{ setValue(field.key, value); }} />}
                <br />
                </span>
            ))}
            <Btn text="Sign Up" onClick={onSignUp} working={working} disabled={!canSignUp()} />
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>
    );
}