
class DateTimeClass {
    d;
    constructor(dt) {
        this.d = new Date(dt);
    }

    label() {
        const ps = this.d.toUTCString().split(' ');
        ps.pop();
        if(this.isToday()) {
            const time = ps.pop();
            return `Today ${time}`;
        }
        return ps.join(' ');
    }

    isToday() {
        const now = new Date();
        return now.getDate() === this.d.getDate() && now.getMonth() === this.d.getMonth() && now.getFullYear() === this.d.getFullYear();
    }
}

export const createDateTimeClass = (dt) => {
    return new DateTimeClass(dt);
}

const dates = {
    createDateTimeClass,
}

export default dates;