import { Button, Spinner } from 'react-bootstrap';
import Icon from './icon';

export default function Btn({text, onClick, variant, working, iconName, iconSize, children, ...rest}) {
    variant = variant || 'secondary'

    const handleOnClick = () => {
        if(onClick) { onClick(); }
    }

    if(working) {
        return (
            <Button variant={variant} onClick={handleOnClick} size='sm'><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}{text || children}</Button>
        );
    }

    switch(iconName) {
        case 'reload':
            iconName = 'BootstrapReboot';
        break;
        case 'close':
            iconName = 'XCircle';
        break;
        case 'download':
            iconName = 'CloudArrowDown';
        break;
        case 'save':
            iconName = 'CheckCircle';
        break;
        case 'back':
            iconName = 'ArrowLeftCircle';
        break;
        case 'open':
            iconName = 'BoxArrowUpRight';
        break;  
        default:

        break;
    }

    let icon = iconName && (<>{' '}<Icon name={iconName} size={iconSize} /></>);

    return (
        <Button variant={variant} onClick={handleOnClick} size='sm'>{text || children}{icon}</Button>
    );

}