import { Row, Col, Tabs, Tab, } from 'react-bootstrap';

import SignIn from './sign-in';
import SignUp from './sign-up';

import utils from 'core/utils';

export default function AmplifyAuth({signIn, signUp}) {

    signIn = utils.toObject(signIn);
    signUp = utils.toObject(signUp);

    return (
        <Row>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6">
            <Tabs defaultActiveKey={'signIn'}>
                <Tab eventKey={'signIn'} title='Sign In'>
                    <SignIn {...signIn} />
                </Tab>
                {signUp.visible !== false && <Tab eventKey={'signUp'} title='Sign Up'>
                    <SignUp {...signUp} />
                </Tab>}
            </Tabs>
        </Col>
        <Col sm="3">&nbsp;</Col>
        </Row>
    );
}