import { signIn as authSignIn, confirmSignIn as authConfirmSignIn } from 'aws-amplify/auth';
import { Row, Col, } from 'react-bootstrap';
import { useState } from 'react';

import Input from 'ui/input';
import Btn from 'ui/btn';

const signInDefaults = {username:'', password:'', message:'', code:''};

export default function SignIn({username}) {
    
    // const { dispatchEvent } = useEvents();
    const [values, setValues] = useState({...signInDefaults, username});
    const [working, setWorking] = useState(false);
    const [codeType, setCodeType] = useState(null);
    const [authUser, setAuthUser] = useState(null);

    const handleSignIn = async() => {
        setValue('message', '');
        setWorking(true);

        let message = '';
        if(!values.username) { message = 'Username missing'; }
        else if(!values.password) { message = 'Password missing'; }
        if(message) { setValue('message', message); return; }
        
        let isSignedIn = false;
        let nextStep;
        try {
            const res = await authSignIn({username:String(values.username).toLowerCase().trim(), password:values.password});
            isSignedIn = res.isSignedIn;
            nextStep = res.nextStep;
        }
        catch(error) {
            setValue('message', error.message);
            setWorking(false);
            return;
        }

        if(nextStep === 'DONE') {
            setWorking(false);
            return;
        }

        if(nextStep) {
            // setCodeType(user?.challengeName);
            // setAuthUser(user);
            setWorking(false);
            return;
        }

        // if(user) {
        //     // window.location.reload();
        //     dispatchEvent('signIn', {user});
        // }
    }

    const setValue = (key, value) => {
        setValues({...values, [key]:value});
    }

    const handleValidate = async() => {
        setValue('message', '');
        setWorking(true);

        let res;
        try {
            res = await authConfirmSignIn(authUser, values.code, codeType);
        }
        catch(error) {
            setValue('message', error.message);
            setWorking(false);
            return;
        }

        // dispatchEvent('signIn', {user:authUser});
        setWorking(false);
    }

    const handleBack = () => {
        setValue('message', '');
        setValue('code', '');
        setCodeType(null);
        setAuthUser(null);
    }

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    if(codeType) {
        return (
        <div className={classes.join(' ')}>
            One-time passcode
            <Input.Text placeholder="eg 135790" value={values.code} onChange={(value)=>{ setValue('code', value); }} autoFocus />
            <br />
            <Btn text="Validate" onClick={handleValidate} working={working} /> <Btn variant={'link'} text="back to Sign In" onClick={handleBack} />
            <br />
            <p>{values.message}</p>
        </div>
        );
    }

    return (
        <div className={classes.join(' ')}>
            <Input.Text value={values.username} placeholder={'email@domain.com'} autoFocus={true} onChange={(username)=>{ setValue('username', username); }} />
            <br />
            <Input.Password placeholder={'password'} onChange={(password)=>{ setValue('password', password); }} />
            <br />
            <p>{values.message}</p>
            <Btn text={'Sign In'} onClick={handleSignIn} working={working} />
        </div>
    );
}