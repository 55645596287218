import { Container, Navbar, Nav, NavLink, Row, Col } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

export default function NavComponent({brandName, signedIn, navItems}) {
    const navigate = useNavigate();

    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="drop-shadow">
            <Container fluid>
                    <Navbar.Brand><span>{brandName}</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    {navItems.length===0 ? null : 
                        signedIn && <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                {navItems.map((c, i)=>{
                                    return (<Nav.Link key={i} as={NavLink} onClick={(e)=>{ e.preventDefault(); if(c.onClick) { c.onClick(); } else { navigate(c.to); } }}>{c.title}</Nav.Link>);
                                })}
                            </Nav>
                        </Navbar.Collapse>
                    }
            </Container>
        </Navbar>
    );

}