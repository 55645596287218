import { useState, useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

import utils from 'core/utils';

export default function SignOutView() {
    const navigate = useNavigate();

    const init = async() => {
        await utils.sleep(1000);
        await signOut();
        navigate('/');
    }

    useEffect(()=>{ init(); }, []);

    return (
        <p align="center">Signing out...</p>
    );
}