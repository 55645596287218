import { useState, useEffect } from 'react';

import 'tinymce/tinymce';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/models/dom/model';

import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/image/plugin';

import { Editor as TinymceEditor } from '@tinymce/tinymce-react';

import utils from 'core/utils';

const defaultEditorConfig = {
    height: 500,
    toolbar: 'undo redo | bold italic underline | backcolor forecolor | styles | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent removeformat | numlist bullist checklist | ' +
        'table image code',
    menubar: false,
    plugins: [
        'table', 'image', 'code',
    ],
    // setup: function (editor) {
    //     console.log(editor);
    // },
    // paste_preprocess: function(plugin, args) { console.log(args); },
    // paste_as_text:true,
    // link_list: [
    //     { title: 'My page 1', value: 'https://www.tiny.cloud' },
    //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
    // link_list: async(resolve) => {
    //     const links = await api.getRequest(`/editor-links`);
    //     resolve(links);
    // },
    // image_list: async(resolve) => {
    //     const files = await api.getRequest(`/files`);
    //     const opts = files.map(f=>({title:f.filename, value:f.url}));
    //     resolve(opts);
    // }
    // image_list: [
    //     { title: 'My page 1', value: 'https://www.tiny.cloud' },
    //     { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
};

export default function Editor({value, onChange, config}) {

    return (
        <TinymceEditor init={config || defaultEditorConfig} value={value} onEditorChange={onChange} />
    );
}