import { Form } from 'react-bootstrap';
import utils from 'core/utils';

export const Text = ({value, type, onChange, size, ...rest}) => {
    if(value===null || value===undefined) { value = ''; }
    if(!type) { type = 'text'; }

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <Form.Control type={type} defaultValue={value} onChange={handleOnChange} size={size || 'sm'} autoComplete="off" {...rest} />
    );
}

export const Hidden = ({...props}) => {
    return <Text {...props} type="hidden" />

}

export const Password = ({value, onChange, ...rest}) => {
    const handleOnChange = (value) => {
        if(onChange) {
            onChange(value);
        }
    }

    return (
        <Text type="password" value={value} onChange={handleOnChange} {...rest} />
    );
}

export const Checkbox = ({text, onChange, inline, ...props}) => {

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.checked);
        }
    }

    const style = inline ? {display:'inline', width:'auto'} : {};

    return (<Form.Check type={'checkbox'} label={text} {...props} onChange={handleOnChange} style={style} />);
}

export const Select = ({value, onChange, opts, size, inline, ...props}) => {
    
    opts = utils.toArray(opts);

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.value);
        }
    }

    if(utils.isNullish(value)) {
        value = '';
    }

    const style = inline ? {display:'inline', width:'auto'} : {};

    return (
        <Form.Select size={size || 'sm'} onChange={handleOnChange} value={value} style={style} {...props}>{opts.map((opt, i)=>(<option value={opt.value} key={i}>{opt.text}</option>))}</Form.Select>
    );
}

export const Ta = ({value, onChange, ...props}) => {
    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <textarea style={{minHeight:'300px'}} className="form-control form-control-sm" onChange={handleOnChange} value={value} {...props}></textarea>
    );
}

export const Date = ({...props}) => {
    return <Text {...props} type="date" />
}

export const Colour = ({...props}) => {
    return <Text {...props} type="color" />
}

const Input = {
    Text,
    Password,
    Checkbox,
    Select,
    Hidden,
    Ta,
    Date,
    Colour,
}

export default Input;