

export const flattenTopics = (topics, level=1) => {
    level = level || 1;
    const data = [];
    for(const topic of topics) {
        // const optionText = `${'--'.repeat(level)} ${topic.title}`;
        // topic.optionText = optionText;
        topic.level = level;
        data.push(topic);
        const childs = flattenTopics(topic.children, level+1);
        for(const child of childs) {
            data.push(child);
        }
    }
    return data;
}

const fyi = {
    flattenTopics,
}

export default fyi;