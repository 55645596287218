const html = `<div class="content span9">
<div class="product-page summary">
    <div class="navbar navbar-resources">

    </div>
</div>
<div class="section-heading">
<ul class="pagination  ">
<li class="sectionmenu hidden-desktop hidden-tablet span8"><!--responsive menu inserted here--></li>
<li class="previous"><a href="/guidance/ng227/chapter/Context" aria-label="Previous chapter"><span class="icon-nice-chevron-left"></span></a></li>
<li class="next"><a href="/guidance/ng227/chapter/Terms-used-in-this-guideline" aria-label="Next chapter"><span class="hidden-phone">Next </span><span class="icon-nice-chevron-right"></span></a></li>
</ul>
<h3 class="h2">Recommendations</h3>
<ul class="unstyled sectiontitles">
<li><a href="#legal-right-to-advocacy">1.1 Legal right to advocacy</a></li>
<li><a href="#who-else-may-benefit-from-advocacy">1.2 Who else may benefit from advocacy</a></li>
<li><a href="#information-about-effective-advocacy-and-signposting-to-services">1.3 Information about effective advocacy and signposting to services</a></li>
<li><a href="#improving-access-to-advocacy">1.4 Improving access to advocacy</a></li>
<li><a href="#enabling-and-supporting-effective-advocacy">1.5 Enabling and supporting effective advocacy</a></li>
<li><a href="#effective-advocacy">1.6 Effective advocacy</a></li>
<li><a href="#partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and">1.7 Partnership working and relationships with families, friends and carers, commissioners and providers</a></li>
<li><a href="#planning-and-commissioning-services-for-advocacy">1.8 Planning and commissioning services for advocacy</a></li>
<li><a href="#training-skills-and-support-for-advocates">1.9 Training, skills and support for advocates</a></li>
<li><a href="#training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates">1.10 Training and skills for health and social care practitioners who work with advocates</a></li>
<li><a href="#monitoring-services-and-collecting-data-for-quality-improvement">1.11 Monitoring services and collecting data for quality improvement</a></li>
</ul>
</div>

<div class="section-summary web-viewer-content">

<div class="chapter" title="Recommendations" id="ng227_recommendations" xmlns="http://www.w3.org/1999/xhtml">
<h2 class="title">
<a id="recommendations"></a>Recommendations</h2>
<div class="panel panel--primary" data-gtm-vis-recent-on-screen797486_812="1115" data-gtm-vis-first-on-screen797486_812="1115" data-gtm-vis-total-visible-time797486_812="100" data-gtm-vis-has-fired797486_812="1">
<p>
<a id="_Hlk46221293"></a>People have the right to be involved in discussions and make informed decisions about their care, as described in <a class="link" href="https://www.nice.org.uk/about/nice-communities/nice-and-the-public/making-decisions-about-your-care" target="_top">NICE's information on making decisions about your care</a>. </p>
<p>
<a class="link" href="https://www.nice.org.uk/about/what-we-do/our-programmes/nice-guidance/nice-guidelines/using-NICE-guidelines-to-make-decisions" target="_top">Making decisions using NICE guidelines</a> explains how we use words to show the strength (or certainty) of our recommendations, and has information about prescribing medicines (including off-label use), professional guidelines, standards and laws (including on consent and mental capacity), and safeguarding.</p>
</div>
<p>The recommendations in this guideline apply to both instructed and <a class="link" href="terms-used-in-this-guideline#non-instructed-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#non-instructed-advocacy">non-instructed advocacy</a>. When providing non-instructed advocacy, advocates will need to take additional steps to determine as far as possible what the person's wishes, feelings and desired outcomes are likely to be, to best represent the person.</p>
<p>If the person lacks the capacity to instruct an advocate, advocates will need to act based on the person's likely wishes, feelings and desired outcomes. </p>
<div class="section" title="1.1 Legal right to advocacy" id="ng227_1.1-legal-right-to-advocacy">
<h3 class="title">
<a id="legal-right-to-advocacy"></a>1.1 Legal right to advocacy</h3>
<div id="ng227_1_1_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.1.1 </span>Advocacy must be offered according to the relevant legislation. The criteria for when and to whom to offer it are described in the:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.legislation.gov.uk/ukpga/2014/23/contents/enacted" target="_top">Care Act 2014</a> and the <a class="link" href="https://www.gov.uk/government/publications/care-act-statutory-guidance/care-and-support-statutory-guidance#independent-advocacy" target="_top">Care and Support statutory guidance</a> for independent advocates for people using social care services</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.legislation.gov.uk/ukpga/2005/9/contents" target="_top">Mental Capacity Act 2005</a> and its <a class="link" href="https://www.gov.uk/government/publications/mental-capacity-act-code-of-practice" target="_top">Code of Practice</a> for independent mental capacity advocates </p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.legislation.gov.uk/ukpga/1983/20/contents" target="_top">Mental Health Act 1983</a> and its <a class="link" href="https://www.gov.uk/government/publications/code-of-practice-mental-health-act-1983" target="_top">Code of Practice</a> for independent mental health advocates.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_1_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.1.2 </span>Local authorities must make appropriate arrangements for independent advocacy services to provide assistance to people making or intending to make complaints as described in the <a class="link" href="https://www.legislation.gov.uk/ukpga/2012/7/contents/enacted" target="_top">Health and Social Care Act 2012</a>. <br><br>For a summary, see box&nbsp;1 on the legal entitlement to advocacy, as well as supporting information and resources on the <a class="link" href="https://www.scie.org.uk/advocacy" target="_top">Social Care Institute for Excellence's information on advocacy</a>. <br><br>For more guidance on helping people to make complaints, see the <a class="link" href="https://www.nice.org.uk/guidance/cg138" target="_top" data-original-url="https://www.nice.org.uk/guidance/CG138">NICE guidelines on patient experience in adult NHS services</a> and <a class="link" href="https://www.nice.org.uk/guidance/cg136" target="_top" data-original-url="https://www.nice.org.uk/guidance/cg136">service user experience in adult mental health</a>.</p>

<strong>Box 1 Legal entitlement to advocacy</strong>

<div class="panel panel--primary indented">
<p>Adapted from the <a class="link" href="https://www.gov.uk/government/publications/care-act-statutory-guidance/care-and-support-statutory-guidance#independent-advocacy" target="_top">Care Act 2014 statutory guidance</a>, the <a class="link" href="https://www.gov.uk/government/publications/mental-capacity-act-code-of-practice" target="_top">Mental Capacity Act Code of Practice</a>, the <a class="link" href="https://www.gov.uk/government/publications/code-of-practice-mental-health-act-1983" target="_top">Mental Health Act 1983: Code of Practice</a> and the <a class="link" href="https://www.legislation.gov.uk/ukpga/2012/7/contents/enacted" target="_top">Health and Social Care Act&nbsp;2012</a>.</p>
<p>
<strong>Care Act 2014</strong>
</p>
<p>From the point of first contact, the local authority must appoint an independent advocate if an adult would experience substantial difficulty in any of these 4&nbsp;areas: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>understanding the information provided</p>
</li>
<li class="listitem">
<p>retaining the information</p>
</li>
<li class="listitem">
<p>using or weighing up the information as part of the process of being involved</p>
</li>
<li class="listitem">
<p>communicating the person's views, wishes or feelings.</p>
</li>
</ul>
</div>
<p>
<strong>And </strong>
</p>
<p>There is thought to be no one appropriate and independent to support and represent the person, for the purpose of facilitating their involvement.</p>
<p>This applies to adults taking part in:</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>a needs assessment</p>
</li>
<li class="listitem">
<p>a carer's assessment</p>
</li>
<li class="listitem">
<p>preparing a care and support or support plan</p>
</li>
<li class="listitem">
<p>revising a care and support or support plan</p>
</li>
<li class="listitem">
<p>a child's needs assessment</p>
</li>
<li class="listitem">
<p>a child's carer's assessment</p>
</li>
<li class="listitem">
<p>a young carer's assessment</p>
</li>
<li class="listitem">
<p>a safeguarding enquiry</p>
</li>
<li class="listitem">
<p>a safeguarding adult review</p>
</li>
<li class="listitem">
<p>an appeal against a local authority decision under Part&nbsp;1 of the Care Act (subject to further consultation).</p>
</li>
</ul>
</div>
<p>
<strong>Care and Support (Independent Advocacy Support) (No. 2) Regulations 2014</strong>
</p>
<p>The Care and Support (Independent Advocacy Support) (No.&nbsp;2) Regulations is a Statutory Instrument, which adds to the Care Act 2014 section&nbsp;67(2). It extends the circumstances in which a person is entitled to an independent advocate to where an assessment or plan is likely to result in a hospital stay of more than 28&nbsp;days or a stay in a care home for more than 8&nbsp;weeks (although there are some exceptions).</p>
<p>
<strong>Mental Capacity Act 2005</strong>
</p>
<p>An independent mental capacity advocate (IMCA) must be instructed, and then consulted, for people lacking capacity who have no one else to support them (other than paid staff), whenever:</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>an NHS body is proposing to provide serious medical treatment <strong>or</strong></p>
</li>
<li class="listitem">
<p>an NHS body or local authority is proposing to arrange accommodation (or a change of accommodation) in hospital or a care home.</p>
</li>
</ul>
</div>
<p>
<strong>And</strong>
</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>the person will stay in hospital longer than 28&nbsp;days <strong>or</strong></p>
</li>
<li class="listitem">
<p>they will stay in the care home for more than 8&nbsp;weeks.</p>
</li>
</ul>
</div>
<p>An IMCA <strong>may</strong> be instructed to support someone who lacks capacity to make decisions concerning:</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>care reviews, if no one else is available to be consulted</p>
</li>
<li class="listitem">
<p>adult protection cases, whether or not family, friends or others are involved.</p>
</li>
</ul>
</div>
<p>
<strong>Mental Health Act&nbsp;1983</strong>
</p>
<p>People are eligible for support from an independent mental health advocate, irrespective of their age, if they are:</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>detained under the Mental Health Act 1983 (excluding certain short-term sections)</p>
</li>
<li class="listitem">
<p>liable to be detained even if not actually detained, including those who are currently on leave of absence from hospital or absent without leave, or those for whom an application or court order for admission has been completed</p>
</li>
<li class="listitem">
<p>conditionally discharged restricted patients</p>
</li>
<li class="listitem">
<p>subject to guardianship</p>
</li>
<li class="listitem">
<p>subject to a community treatment order</p>
</li>
<li class="listitem">
<p>being considered for treatment under section&nbsp;57 of the Act or, for under‑18s, any treatment under section&nbsp;58A.</p>
</li>
</ul>
</div>
<p>
<strong>Health and Social Care Act&nbsp;2012</strong>
</p>
<p>The 2012 Health and Social Care Act amendment to the 2007 Local Government and Public Involvement in Health Act.</p>
<p>The local authority must make arrangements for independent advocacy services to provide assistance to people making or intending to make a complaint:</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>under a procedure operated by a health service body or independent provider</p>
</li>
<li class="listitem">
<p>section&nbsp;113(1) or (2) of the Health and Social Care (Community Health and Standards) Act&nbsp;2003</p>
</li>
<li class="listitem">
<p>to the Health Service Commissioner for England</p>
</li>
<li class="listitem">
<p>to the Public Services Ombudsman for Wales, which relates to a Welsh health body </p>
</li>
<li class="listitem">
<p>under section&nbsp;73C(1) of the National Health Service Act&nbsp;2006</p>
</li>
<li class="listitem">
<p>to a Local Commissioner under Part&nbsp;3 of the Local Government Act 1974 about a matter which could be the subject of a complaint under section&nbsp;73C(1) of the National Health Service Act&nbsp;2006</p>
</li>
<li class="listitem">
<p>of such description as the Secretary of State may by regulations prescribe which relates to the provision of services as part of the health service and is made under a procedure of a description prescribed in the regulations, or gives rise, or may give rise, to proceedings of a description prescribed in the regulations.</p>
</li>
</ul>
</div>
</div>
<div class="panel indented">
<p>
<a id="_Hlk47684920"></a>For a short explanation of why the committee made these recommendations and how they might affect practice, see the <a class="link" href="rationale-and-impact#legal-right-to-advocacy-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#legal-right-to-advocacy-2" aria-controls="legal-right-to-advocacy-2" aria-expanded="false" role="button">rationale and impact section on legal right to advocacy&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>
<a id="_Hlk25825429"></a>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/a-who-has-a-legal-right-to-advocacy-pdf-11258473790" target="_top">evidence review A: who has a legal right to advocacy?</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/e-enabling-and-supporting-effective-advocacy-pdf-11258473794" target="_top">evidence review E: enabling and supporting effective advocacy</a>. </p>
</li>
</ul>
</div>
<aside id="legal-right-to-advocacy-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="legal-right-to-advocacy-2" type="button">Hide rationale and impact section on legal right to advocacy&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.2 Who else may benefit from advocacy" id="ng227_1.2-who-else-may-benefit-from-advocacy">
<h3 class="title">
<a id="who-else-may-benefit-from-advocacy"></a>1.2 Who else may benefit from advocacy</h3>
<div id="ng227_1_2_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.2.1 </span>Offer advocacy to people who are not covered by the legal entitlement but who would otherwise not be able to express their views or sufficiently influence decisions that are likely to have a substantial impact on their <a class="link" href="terms-used-in-this-guideline#wellbeing" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#wellbeing">wellbeing</a> or the wellbeing of someone they have caring or parental responsibility for. </p>
<div class="panel indented">
<p>For a short explanation of why the committee made this recommendation and how it might affect practice or services, see the <a class="link" href="rationale-and-impact#who-else-may-benefit-from-advocacy-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#who-else-may-benefit-from-advocacy-2" aria-controls="who-else-may-benefit-from-advocacy-2" aria-expanded="false" role="button">rationale and impact section on who else may benefit from advocacy&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in <a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/b-who-else-would-benefit-from-advocacy-and-how-do-we-identify-them-pdf-11258473791" target="_top">evidence review&nbsp;B: who else would benefit from advocacy and how do we identify them?</a></p>
<aside id="who-else-may-benefit-from-advocacy-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="who-else-may-benefit-from-advocacy-2" type="button">Hide rationale and impact section on who else may benefit from advocacy&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.3 Information about effective advocacy and signposting to services" id="ng227_1.3-information-about-effective-advocacy-and-signposting-to-services">
<h3 class="title">
<a id="information-about-effective-advocacy-and-signposting-to-services"></a>1.3 Information about effective advocacy and signposting to services</h3>
<div id="ng227_1_3_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.3.1 </span>Local authorities must meet the requirement of the Care Act 2014 to make information and advice publicly available about care and support services for adults in their area. This should include advocacy services.</p>
</div>
<div id="ng227_1_3_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.3.2 </span>Local authorities, health authorities, NHS trusts, health and social care providers and advocacy services should provide everyone legally entitled to advocacy (including young people who are using adult services) with information about their entitlement to advocacy and what this means. There should be proactive signposting to the information using accessible formats. </p>
</div>
<div id="ng227_1_3_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.3.3 </span>
<a id="_Hlk110936392"></a>Local authorities, health authorities, NHS trusts, health and social care providers and advocacy services should provide everyone who would benefit from advocacy (whether or not they are legally entitled to it) with information about:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>what advocacy services are available to them</p>
</li>
<li class="listitem">
<p>how an advocate could help them </p>
</li>
<li class="listitem">
<p>how to access and contact advocacy services.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_3_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.3.4 </span>Local authorities, health authorities, NHS trusts, health and social care providers and advocacy services should ensure that all information about advocacy is provided in a variety of ways to suit people's needs (including for family, friends and carers), using accessible formats where relevant. Examples include using interpreters, sign language and versions such as Easy Read, large print, braille and audio. For more guidance on communicating and providing information, see the <a class="link" href="https://www.nice.org.uk/guidance/cg138" target="_top" data-original-url="https://www.nice.org.uk/guidance/cg138">NICE guideline on patient experience in adult NHS services</a> and the <a class="link" href="https://www.england.nhs.uk/ourwork/accessibleinfo/" target="_top">NHS Accessible Information Standard</a>.</p>
</div>
<div id="ng227_1_3_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.3.5 </span>Local authorities, health authorities, NHS trusts, health and social care providers and advocacy services should repeat information about advocacy and how to access it at each key point in the person's interaction with health and social care.</p>
</div>
<div id="ng227_1_3_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.3.6 </span>If a person is offered healthcare, care or support out of their home area, the organisation arranging the placement should give them (and their family, friends or carers, as appropriate) information about the advocacy support available and help them to access it.</p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#information-about-effective-advocacy-and-signposting-to-services-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#information-about-effective-advocacy-and-signposting-to-services-2" aria-controls="information-about-effective-advocacy-and-signposting-to-services-2" aria-expanded="false" role="button">rationale and impact section on information about effective advocacy and signposting to services&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/c-information-about-effective-advocacy-and-signposting-to-services-pdf-11258473792" target="_top">evidence review C: information about effective advocacy and signposting to services</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/f-what-does-effective-advocacy-look-like-pdf-11258473795" target="_top">evidence review F: what does effective advocacy look like? </a>
</p>
</li>
</ul>
</div>
<aside id="information-about-effective-advocacy-and-signposting-to-services-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="information-about-effective-advocacy-and-signposting-to-services-2" type="button">Hide rationale and impact section on information about effective advocacy and signposting to services&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.4 Improving access to advocacy" id="ng227_1.4-improving-access-to-advocacy">
<h3 class="title">
<a id="improving-access-to-advocacy"></a>1.4 Improving access to advocacy</h3>
<p>This section should be read alongside the <a class="link" href="recommendations#training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates" target="_top" rel="internal" data-chapter="recommendations" data-original-url="#training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates">section on training and skills for health and social care practitioners who work with advocates</a>. </p>
<div id="ng227_1_4_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.1 </span>Health and social care providers should ensure that advocates can meet people in person to support them to make initial contact with advocacy services. </p>
</div>
<div id="ng227_1_4_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.2 </span>Health and social care providers in all settings, including hospitals, care homes and prisons, should ensure that policies and procedures do not act as an obstacle to people accessing advocacy. This includes finding alternative methods to mitigate any risks, for example from infection. </p>
</div>
<div id="ng227_1_4_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.3 </span>If a person has been detained under the <a class="link" href="https://www.legislation.gov.uk/ukpga/1983/20/contents" target="_top">Mental Health Act 1983</a> and has legal representation, they still have a legal right to advocacy and therefore mental health services must continue to facilitate access to independent mental health advocacy (IMHA) support. See the <a class="link" href="recommendations#legal-right-to-advocacy" target="_top" rel="internal" data-chapter="recommendations" data-original-url="#legal-right-to-advocacy">section on legal right to advocacy</a>.</p>
</div>
<div id="ng227_1_4_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.4 </span>Commissioners and advocacy providers should make it easy for people to access advocacy by having: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>flexible ways to make contact, including by self-referral </p>
</li>
<li class="listitem">
<p>a simple process that directs people to the right advocacy support without them needing to know what type of advocacy they need (for example, a universal point of access). </p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_4_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.5 </span>Advocacy providers should aim to support continuity by offering people the same advocate for different types of advocacy (for example, statutory advocacy in line with the <a class="link" href="https://www.legislation.gov.uk/ukpga/2014/23/contents/enacted" target="_top">Care Act 2014</a>, IMHA, independent mental capacity advocate [IMCA] and non-statutory advocacy). If this is not possible, they should ensure that systems for handover are in place that are not reliant on a new referral.</p>
</div>
<div id="ng227_1_4_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.6 </span>Independent mental health advocates should make regular visits to inpatient settings to identify people who would benefit from advocacy and help them to access it. This includes taking all necessary steps to ensure that people who would otherwise be unable to instruct an advocate, or who would find it particularly difficult, do not miss out on statutory advocacy services. Particular efforts should also be made to facilitate access to advocacy for people in isolation, seclusion or segregation. </p>
</div>
<div id="ng227_1_4_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.7 </span>Advocacy providers, hospital and health trusts and commissioners should offer IMHA on an <a class="link" href="terms-used-in-this-guideline#opt-out" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#opt-out">opt-out</a> basis so that everyone who is eligible meets an advocate and is offered the service. </p>
</div>
<div id="ng227_1_4_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.8 </span>Advocacy organisations should ensure that IMHA is offered at the earliest opportunity and then regularly afterwards to people who are eligible. This includes people who have initially declined support. For these people, advocacy organisations should explore, where appropriate, the reasons why the support was declined and what could be done to help them access advocacy. </p>
</div>
<div id="ng227_1_4_9" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.9 </span>IMHA services should raise awareness of disabled people's organisations and user-led organisations, <a class="link" href="terms-used-in-this-guideline#self-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#self-advocacy">self-advocacy</a> groups or patient participation forums and promote <a class="link" href="terms-used-in-this-guideline#peer-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#peer-advocacy">peer advocacy</a> and self-advocacy options.</p>
</div>
<div id="ng227_1_4_10" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.10 </span>Advocacy organisations should have a plan for how to ensure that their services are taken up by the people with the greatest need, who may not be able to ask for them.</p>
</div>
<div id="ng227_1_4_11" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.11 </span>Local authorities and advocacy providers should collaborate to make it clear how people can access advocacy and how they can provide support to help them to do so if they: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>are supported outside of their home area <strong>or</strong></p>
</li>
<li class="listitem">
<p>are carers who care for someone outside their area.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_4_12" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.4.12 </span>Health and social care practitioners should ensure that people who are unable to ask for an advocate get advocacy when they are entitled to it. </p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#improving-access-to-advocacy-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#improving-access-to-advocacy-2" aria-controls="improving-access-to-advocacy-2" aria-expanded="false" role="button">rationale and impact section on improving access to advocacy&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>. </p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/d-improving-access-to-advocacy-pdf-11258473793" target="_top">evidence review D: improving access to advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/e-enabling-and-supporting-effective-advocacy-pdf-11258473794" target="_top">evidence review E: enabling and supporting effective advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/g-partnership-working-and-relationships-with-families-and-carers-commissioners-and-providers-pdf-11258473796" target="_top">evidence review G: partnership working and relationships with families and carers, commissioners and providers</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/h-planning-and-commissioning-services-for-advocacy-pdf-11258473797" target="_top">evidence review H: planning and commissioning services for advocacy</a>.</p>
</li>
</ul>
</div>
<aside id="improving-access-to-advocacy-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="improving-access-to-advocacy-2" type="button">Hide rationale and impact section on improving access to advocacy&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.5 Enabling and supporting effective advocacy" id="ng227_1.5-enabling-and-supporting-effective-advocacy">
<h3 class="title">
<a id="enabling-and-supporting-effective-advocacy"></a>1.5 Enabling and supporting effective advocacy</h3>
<div id="ng227_1_5_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.1 </span>Health and social care practitioners and other referrers should: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>identify the need for advocacy as early as possible <strong>and</strong></p>
</li>
<li class="listitem">
<p>make a referral to an advocacy service without delay.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_5_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.2 </span>When the need for advocacy is identified, allow enough time:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>to appoint an advocate if the person does not have one</p>
</li>
<li class="listitem">
<p>to make any other arrangements, for example if the person needs an interpreter</p>
</li>
<li class="listitem">
<p>for the advocate to help the person prepare before any meetings or discussions, and to ensure they understand the outcome afterwards.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_5_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.3 </span>
<a id="_Hlk96076571"></a>Service providers should accommodate the availability of the advocate when planning and scheduling meetings, ward rounds or other situations where decisions are being made, including rearranging meetings where needed and practicable.</p>
</div>
<div id="ng227_1_5_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.4 </span>If people have not had enough time to prepare with their advocate before a meeting, their advocate should support them in requesting to rearrange the meeting.</p>
</div>
<div id="ng227_1_5_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.5 </span>Advocacy organisations should ensure that there is adequate time for the advocate and person to build relationships and trust according to their individual needs. </p>
</div>
<div id="ng227_1_5_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.6 </span>Service providers should ensure that people can have discussions with their advocates in a private area where they can talk in confidence without being overheard.</p>
</div>
<div id="ng227_1_5_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.7 </span>Health and social care practitioners should involve a person's advocate in all discussions with the person until a decision has been made and explained to the person, and they have had a chance to challenge the decision if they want to.</p>
</div>
<div id="ng227_1_5_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.8 </span>Health and social care practitioners should facilitate advocacy, for example by:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>respecting the advocate's independence </p>
</li>
<li class="listitem">
<p>sharing information appropriately with advocates </p>
</li>
<li class="listitem">
<p>supporting the person and building good working relationships with them</p>
</li>
<li class="listitem">
<p>encouraging and supporting ongoing contact between the person and their advocate</p>
</li>
<li class="listitem">
<p>giving the person privacy to talk to their advocate</p>
</li>
<li class="listitem">
<p>supporting people to understand about advocacy and to ask for the advocacy that they would want, or ask for it on their behalf if appropriate</p>
</li>
<li class="listitem">
<p>responding to advocates in a timely manner</p>
</li>
<li class="listitem">
<p>supporting any communication needs, such as arranging for an interpreter.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_5_9" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.9 </span>Health and social care providers should offer practical support to help people to communicate with their advocate remotely. This may include providing:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>access to computers, the internet and phones</p>
</li>
<li class="listitem">
<p>support to use technology</p>
</li>
<li class="listitem">
<p>help to schedule and remember meeting times.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_5_10" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.10 </span>Advocacy providers should use <a class="link" href="terms-used-in-this-guideline#digital-platforms" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#digital-platforms">digital platforms</a> to communicate with the person when necessary or the person prefers it, and only when it is safe, effective and appropriate to do so.</p>
</div>
<div id="ng227_1_5_11" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.11 </span>Health and social care practitioners responsible for decisions should ensure that all formal and informal concerns that are raised, by either the person or the advocate on their behalf, are understood, responded to and recorded. </p>
</div>
<div id="ng227_1_5_12" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.12 </span>Health and social care providers should periodically audit cases to assess whether referrals have been made to advocacy services in line with statutory duties.</p>
</div>
<div id="ng227_1_5_13" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.13 </span>If gaps in compliance (for example, people not being informed of their right to an advocate) are identified by audits, or otherwise, health and social care providers should develop action plans to improve compliance.</p>
</div>
<div id="ng227_1_5_14" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.14 </span>Local authorities and health and social care providers should consider including the numbers of referrals they make to advocacy services as a part of their corporate performance information.</p>
</div>
<div id="ng227_1_5_15" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.15 </span>Advocacy services should ensure that advocacy staff know when and how to report and act on safeguarding concerns.</p>
</div>
<div id="ng227_1_5_16" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.5.16 </span>Advocacy services should ensure that their advocacy staff are delivering effective safeguarding by:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>having robust internal guidance</p>
</li>
<li class="listitem">
<p>keeping detailed, accurate records that are written at the time of the discussion or event</p>
</li>
<li class="listitem">
<p>appointing a safeguarding lead </p>
</li>
<li class="listitem">
<p>developing systems for tracking and monitoring concerns</p>
</li>
<li class="listitem">
<p>training, supervision and <a class="link" href="terms-used-in-this-guideline#reflective-practice" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#reflective-practice">reflective practice</a></p>
</li>
<li class="listitem">
<p>providing input to local Safeguarding Adults Boards</p>
</li>
<li class="listitem">
<p>learning from adverse events</p>
</li>
<li class="listitem">
<p>continuing to advocate for the person throughout the process.<br><br>For more guidance on communicating and discussing complex information, see the <a class="link" href="https://www.nice.org.uk/guidance/ng86" target="_top" data-original-url="https://www.nice.org.uk/guidance/ng86">NICE guidelines on people's experience in adult social care services</a>, <a class="link" href="https://www.nice.org.uk/guidance/cg138" target="_top" data-original-url="https://www.nice.org.uk/guidance/cg138">patient experience in adult NHS services</a> and <a class="link" href="https://www.nice.org.uk/guidance/ng197" target="_top" data-original-url="https://www.nice.org.uk/guidance/ng197">shared decision making</a>.</p>
</li>
</ul>
</div>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#enabling-and-supporting-effective-advocacy-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#enabling-and-supporting-effective-advocacy-2" aria-controls="enabling-and-supporting-effective-advocacy-2" aria-expanded="false" role="button">rationale and impact section on enabling and supporting effective advocacy&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/d-improving-access-to-advocacy-pdf-11258473793" target="_top">evidence review D: improving access to advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/e-enabling-and-supporting-effective-advocacy-pdf-11258473794" target="_top">evidence review E: enabling and supporting effective advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/f-what-does-effective-advocacy-look-like-pdf-11258473795" target="_top">evidence review F: what does effective advocacy look like?</a>
</p>
</li>
</ul>
</div>
<aside id="enabling-and-supporting-effective-advocacy-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="enabling-and-supporting-effective-advocacy-2" type="button">Hide rationale and impact section on enabling and supporting effective advocacy&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.6 Effective advocacy" id="ng227_1.6-effective-advocacy">
<h3 class="title">
<a id="effective-advocacy"></a>1.6 Effective advocacy</h3>
<div id="ng227_1_6_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.1 </span>Advocacy providers should ensure that their advocacy service is accessible, for example by: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>making face-to-face advocacy available unless this is not possible </p>
</li>
<li class="listitem">
<p>using remote advocacy if the person prefers this and it is effective</p>
</li>
<li class="listitem">
<p>operating outside normal working hours if possible as well as during them </p>
</li>
<li class="listitem">
<p>making referral processes simple, flexible and clear</p>
</li>
<li class="listitem">
<p>making referral forms easily available online</p>
</li>
<li class="listitem">
<p>ensuring that meeting places are accessible in all aspects</p>
</li>
<li class="listitem">
<p>clearly describing available services </p>
</li>
<li class="listitem">
<p>producing policies, procedures and publicity materials in accessible formats, including Easy Read</p>
</li>
<li class="listitem">
<p>meeting people's communication needs</p>
</li>
<li class="listitem">
<p>providing advocacy free of charge for people who are eligible</p>
</li>
<li class="listitem">
<p>making efforts (directly and indirectly through other organisations) to reach under-represented and underserved communities</p>
</li>
<li class="listitem">
<p>providing <a class="link" href="terms-used-in-this-guideline#non-instructed-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#non-instructed-advocacy">non-instructed advocacy</a>.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_6_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.2 </span>Advocacy providers should ensure that their advocacy service is person centred, for example by:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>ensuring that advocates are directed by the wishes and interests of the person they are advocating for</p>
</li>
<li class="listitem">
<p>being non-judgemental and respectful of the person's needs, views, values, culture and experiences</p>
</li>
<li class="listitem">
<p>avoiding and challenging stereotyping</p>
</li>
<li class="listitem">
<p>supporting and helping the person to <a class="link" href="terms-used-in-this-guideline#self-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#self-advocacy">self-advocate</a> as much as possible</p>
</li>
<li class="listitem">
<p>supporting the person to choose their own level of involvement and the way they and their advocate work together to progress matters</p>
</li>
<li class="listitem">
<p>enabling the person to lead and be involved in addressing the advocacy issue or decision-making processes</p>
</li>
<li class="listitem">
<p>clearly agreeing with the person their advocacy needs, their impact and desired outcomes</p>
</li>
<li class="listitem">
<p>only consulting, meeting or accepting information and documentation from third parties with the consent of the person, or if the person is unable to consent and it is in their best interests</p>
</li>
<li class="listitem">
<p>offering a choice of advocate (for example, gender and culture) for people seeking support.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_6_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.3 </span>Advocates should work with the person they are supporting to develop a shared understanding of what the person wants to achieve. They should discuss and agree with the person whether they have achieved the outcome they wanted and what to do if this does not happen, and review regularly.</p>
</div>
<div id="ng227_1_6_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.4 </span>When people lack capacity to instruct their advocate, advocacy providers should ensure that the advocacy remains person led and involves people with an interest in the person's welfare. </p>
</div>
<div id="ng227_1_6_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.5 </span>Advocacy providers should include people with lived experience of <a class="link" href="terms-used-in-this-guideline#health-inequalities" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#health-inequalities">health inequalities</a> or using health and social care or advocacy services in their organisation, for example, as paid advocates or as part of management committees or boards.</p>
</div>
<div id="ng227_1_6_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.6 </span>Advocacy providers must promote equality throughout their services for everyone with protected characteristics under the <a class="link" href="https://www.legislation.gov.uk/ukpga/2010/15/contents" target="_top">Equality Act&nbsp;2010</a>.</p>
</div>
<div id="ng227_1_6_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.7 </span>Advocacy providers should deliver effective advocacy in relation to safeguarding by supporting their advocates to:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>be sensitive and alert to what the person is telling them and to observe the person's communications and circumstances to identify any safeguarding concerns</p>
</li>
<li class="listitem">
<p>respond to concerns about poor practice that fall below the threshold for safeguarding </p>
</li>
<li class="listitem">
<p>challenge decisions if safeguarding concerns have been raised but the local authority has decided they do not meet the threshold for action</p>
</li>
<li class="listitem">
<p>continue to advocate for a person throughout any safeguarding processes</p>
</li>
<li class="listitem">
<p>take action if they observe other safeguarding issues while they are advocating for a person</p>
</li>
<li class="listitem">
<p>provide non-instructed advocacy.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_6_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.8 </span>Advocacy providers should ensure that the same advocate works with a person throughout the advocacy process, if possible and the person prefers it.</p>
</div>
<div id="ng227_1_6_9" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.9 </span>Advocacy providers should maintain independence from any other organisations the person is in contact with, to avoid any conflict of interest. Ways to do this include:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>establishing themselves as a free-standing organisation with governance documents that promote and protect their independence</p>
</li>
<li class="listitem">
<p>ensuring that their independence is clearly reflected in all publicity material, including on their website</p>
</li>
<li class="listitem">
<p>ensuring that their service is structurally independent of any other services offered</p>
</li>
<li class="listitem">
<p>developing an organisational culture that encourages advocates to challenge freely and as directed by the people they are working with</p>
</li>
<li class="listitem">
<p>having a conflict of interests policy, keeping a register of conflicts that might influence board members, staff and volunteers, and ensuring that advocates are free from any conflicts of interest</p>
</li>
<li class="listitem">
<p>actively seeking funding from more than 1&nbsp;source</p>
</li>
<li class="listitem">
<p>ensuring that funders, commissioners and external health and social care practitioners are not involved in organisational decisions such as how or by whom advocacy is delivered</p>
</li>
<li class="listitem">
<p>putting in place engagement protocols that govern the organisation's interaction with other organisations.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_6_10" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.10 </span>Advocacy providers should, wherever possible, have advocates specialising in different types of advocacy and multi-skilled advocates who can provide different types of advocacy to the same person.</p>
</div>
<div id="ng227_1_6_11" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.11 </span>Advocacy services should ensure that they can provide access to interpretation and translation services when the person needs them.</p>
</div>
<div id="ng227_1_6_12" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.12 </span>Advocacy services should ensure that advocacy is culturally appropriate by respecting and taking into account the person's cultural needs, preferences, customs or religious beliefs and experience of health inequalities.</p>
</div>
<div id="ng227_1_6_13" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.13 </span>Advocacy services should support their staff to develop <a class="link" href="terms-used-in-this-guideline#cultural-competence" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#cultural-competence">cultural competence</a> to meet the needs of the populations in their local areas, for example by training, supervision and <a class="link" href="terms-used-in-this-guideline#reflective-practice" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#reflective-practice">reflective practice</a>.</p>
</div>
<div id="ng227_1_6_14" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.14 </span>Advocates should maintain confidentiality, and explain the principles and the limitations of confidentiality in advance to people they are supporting. This should include: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>what information will be shared, who with, and when <strong>and</strong></p>
</li>
<li class="listitem">
<p>when confidentiality may need to be breached, for example, to make a child or adult safeguarding alert or when required by law.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_6_15" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.6.15 </span>Advocacy providers should work together to promote best practice and consistency. This could be done, for example, by sharing learning, insight and tools, and developing joint publications, guidance and resources.</p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#effective-advocacy-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#effective-advocacy-2" aria-controls="effective-advocacy-2" aria-expanded="false" role="button">rationale and impact section on effective advocacy&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in <a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/f-what-does-effective-advocacy-look-like-pdf-11258473795" target="_top">evidence review&nbsp;F: what does effective advocacy look like? </a></p>
<aside id="effective-advocacy-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="effective-advocacy-2" type="button">Hide rationale and impact section on effective advocacy&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.7 Partnership working and relationships with families, friends and carers, commissioners and providers" id="ng227_1.7-partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and-providers">
<h3 class="title">
<a id="partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and"></a>1.7 Partnership working and relationships with families, friends and carers, commissioners and providers</h3>
<div id="ng227_1_7_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.1 </span>Advocates should liaise with family members, friends and carers <a id="_Hlk112695142"></a>when the person wants them to or when the person cannot express a view about this but it is in their best interests. This includes, for example, and where appropriate:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>seeking information from family members, friends and carers to help understand the person's circumstances, communication preferences, views and wishes</p>
</li>
<li class="listitem">
<p>sharing information with family members, friends and carers about the work that they are doing on the person's behalf.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_7_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.2 </span>Advocacy providers should be familiar with local support services, such as health, social care, education, employment support and community action, and what these services offer so that they can give up-to-date and accurate information to people accessing advocacy.</p>
</div>
<div id="ng227_1_7_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.3 </span>Safeguarding Adults Boards should ensure that they have input from advocacy providers, for example by having them as board members and giving them the opportunity to give feedback about services.</p>
</div>
<div id="ng227_1_7_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.4 </span>Commissioners should support advocacy providers to ensure that information is available to people who may use advocacy services, for example ensuring there is enough time in contracts to develop and provide the information in accessible formats. </p>
</div>
<div id="ng227_1_7_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.5 </span>Advocacy providers should work with commissioners and service providers to develop protocols that facilitate effective advocacy (for example, referrals, engagement and dispute resolution).</p>
</div>
<div id="ng227_1_7_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.6 </span>Commissioners of advocacy services should work with other local commissioners and commissioning bodies, and those in other geographical areas, to:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>identify and address any current gaps in services </p>
</li>
<li class="listitem">
<p>develop a long-term view of what advocacy services are needed and plan how to achieve this.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_7_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.7 </span>Practitioners should share relevant elements of individual risk assessments and safety plans with advocates to ensure their safety, and the safety of the people they support.</p>
</div>
<div id="ng227_1_7_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.8 </span>Health and social care providers and advocacy providers should ensure that their staff understand when and how advocates can access a person's records, in line with legislation.</p>
</div>
<div id="ng227_1_7_9" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.9 </span>Commissioners of IMHA services should work in partnership with commissioners of mental health services to understand and maximise the impact of IMHA provision on mental health service development. </p>
</div>
<div id="ng227_1_7_10" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.10 </span>Advocacy providers and commissioners should work in partnership with other organisations to ensure culturally appropriate advocacy that meets local needs. For example, by: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>providing advocacy as an integral part of wider Black community and voluntary sector mental health service </p>
</li>
<li class="listitem">
<p>working closely with a south Asian community group to share insights and improve access to advocacy </p>
</li>
<li class="listitem">
<p>providing mental health advocacy as a discrete casework advocacy service managed by a Black community and voluntary sector service </p>
</li>
<li class="listitem">
<p>increasing the diversity of staff within advocacy services to reflect the local population</p>
</li>
<li class="listitem">
<p>co-locating different types of advocacy services, for example, an African and Caribbean advocacy service located in the same community centre as a mental health advocacy service. </p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_7_11" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.7.11 </span>Advocacy providers should liaise with and facilitate the regulator in carrying out their role, including in inspecting regulated services.</p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and-2" aria-controls="partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and-2" aria-expanded="false" role="button">rationale and impact section on partnership working and relationships with families, friends and carers, commissioners and providers&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in <a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/g-partnership-working-and-relationships-with-families-and-carers-commissioners-and-providers-pdf-11258473796" target="_top">evidence review&nbsp;G: partnership working and relationships with families and carers, commissioners and providers</a>.</p>
<aside id="partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="partnership-working-and-relationships-with-families-friends-and-carers-commissioners-and-2" type="button">Hide rationale and impact section on partnership working and relationships with families, friends and carers, commissioners and providers&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.8 Planning and commissioning services for advocacy" id="ng227_1.8-planning-and-commissioning-services-for-advocacy">
<h3 class="title">
<a id="planning-and-commissioning-services-for-advocacy"></a>1.8 Planning and commissioning services for advocacy</h3>
<div id="ng227_1_8_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.1 </span>Commission advocacy services based on an assessment of local need, building on the Joint Strategic Needs Assessment and taking into account the effects of structural, systemic and <a class="link" href="terms-used-in-this-guideline#health-inequalities" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#health-inequalities">health inequalities</a> on the population, in co-production with people who use health and social care services.</p>
</div>
<div id="ng227_1_8_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.2 </span>Commissioning bodies in a locality should work together to agree and publish a long-term plan for advocacy based on the assessment of need. Commissioners should take into account the broad range of advocacy needs when planning and commissioning advocacy. This includes the need for statutory and non-statutory advocacy, <a class="link" href="terms-used-in-this-guideline#peer-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#peer-advocacy">peer advocacy</a> and <a class="link" href="terms-used-in-this-guideline#self-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#self-advocacy">self-advocacy</a>. </p>
</div>
<div id="ng227_1_8_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.3 </span>Consider commissioning advocacy services that can also be used by people who do not meet the criteria for statutory advocacy but could benefit from using them (see the <a class="link" href="recommendations#who-else-may-benefit-from-advocacy" target="_top" rel="internal" data-chapter="recommendations" data-original-url="#who-else-may-benefit-from-advocacy">section on who else may benefit from advocacy</a>).</p>
</div>
<div id="ng227_1_8_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.4 </span>
<a id="_Hlk101945542"></a>Consider taking into account wider public policies, strategy, legislation and guidance to inform advocacy commissioning decisions. </p>
</div>
<div id="ng227_1_8_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.5 </span>Local authorities and commissioners should engage with health and social care service providers and community stakeholders to help them understand and address gaps in advocacy provision, including their duty to develop the market under the <a class="link" href="https://www.legislation.gov.uk/ukpga/2014/23/contents/enacted" target="_top">Care Act&nbsp;2014</a>.</p>
</div>
<div id="ng227_1_8_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.6 </span>Commissioners and local authorities should involve people who use advocacy services in planning and designing advocacy services, including in monitoring contracts. For more guidance on involving people who use services, see the <a class="link" href="https://www.nice.org.uk/guidance/ng44" target="_top" data-original-url="https://www.nice.org.uk/guidance/ng44">NICE guideline on community engagement</a>.</p>
</div>
<div id="ng227_1_8_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.7 </span>Commissioners must ensure that sufficient advocacy services are available to meet statutory duties for people who are detained or deprived of their liberty in independent hospitals. </p>
</div>
<div id="ng227_1_8_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.8 </span>Commissioners should ensure that contracts support advocacy providers to maintain their independence and operate in line with advocacy principles, for example by avoiding caps on the number of hours an advocate can spend supporting someone. </p>
</div>
<div id="ng227_1_8_9" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.9 </span>When drafting contracts and specifications for advocacy services, commissioners should take account of the overall resources needed, so that providers have enough time and funding for advocates to undertake continuing professional development and training.</p>
</div>
<div id="ng227_1_8_10" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.10 </span>Consider the benefits of advocacy providers having an external quality accreditation, such as the <a class="link" href="https://www.ndti.org.uk/projects/advocacy-quality-performance-mark" target="_top">Quality Performance Mark</a>. </p>
</div>
<div id="ng227_1_8_11" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.11 </span>Commissioners should ensure that service specifications, service costs and contracts with advocacy service providers specify that the service should be person centred and based on the relationship between the person and their advocate. For example, specify that advocacy services: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>allow the person to receive advocacy on issues that have a major impact on their health and social care needs </p>
</li>
<li class="listitem">
<p>ensure adequate and long-term support for people in situations that place them at high risk (for example, risk of exclusion or abuse).</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_8_12" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.12 </span>When planning and providing support, commissioners and advocacy providers should consider whether reasonable adjustments can be made to protect against or help the person deal with discrimination or inequalities arising from a person's protected characteristics as defined by the <a class="link" href="https://www.legislation.gov.uk/ukpga/2010/15/contents" target="_top">Equality Act 2010</a>, or from other <a id="_Hlk115786964"></a>life circumstances and experiences such as health inequalities (see box&nbsp;2).</p>

<strong>Box 2 Characteristics, life circumstances or life experiences relating to inequalities</strong>

<div class="panel panel--primary indented">
<p>
<strong>Protected characteristics of the Equality Act 2010</strong>
</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>age</p>
</li>
<li class="listitem">
<p>disability</p>
</li>
<li class="listitem">
<p>gender reassignment</p>
</li>
<li class="listitem">
<p>marriage and civil partnership</p>
</li>
<li class="listitem">
<p>pregnancy and maternity</p>
</li>
<li class="listitem">
<p>race</p>
</li>
<li class="listitem">
<p>religion or belief</p>
</li>
<li class="listitem">
<p>sex</p>
</li>
<li class="listitem">
<p>sexual orientation.</p>
</li>
</ul>
</div>
<p>
<strong>Examples of life circumstances and experiences that could lead to discrimination or inequalities</strong>
</p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>transitioning from children's to adult care services</p>
</li>
<li class="listitem">
<p>communication impairment</p>
</li>
<li class="listitem">
<p>learning difficulties</p>
</li>
<li class="listitem">
<p>learning disability</p>
</li>
<li class="listitem">
<p>poor literacy</p>
</li>
<li class="listitem">
<p>refugee status</p>
</li>
<li class="listitem">
<p>English not being a first language</p>
</li>
<li class="listitem">
<p>being an offender</p>
</li>
<li class="listitem">
<p>homelessness</p>
</li>
<li class="listitem">
<p>being from a Gypsy, Roma or Traveller community</p>
</li>
<li class="listitem">
<p>coercive control</p>
</li>
<li class="listitem">
<p>health inequalities.</p>
</li>
</ul>
</div>
<p>Note: Some people could have multiple protected characteristics or life circumstances and experiences listed here and <a class="link" href="terms-used-in-this-guideline#intersectionality" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#intersectionality">intersectionality</a> may occur.</p>
</div>
</div>
<div id="ng227_1_8_13" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.13 </span>Commissioners and advocacy providers should consider working with local organisations that have the skills, knowledge and networks to help promote access to advocacy for underserved groups <a id="_Hlk100733801"></a>(for example, people with refugee status and people from Gypsy, Roma and Traveller communities).</p>
</div>
<div id="ng227_1_8_14" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.14 </span>When commissioning advocacy services, consider commissioning flexibility in services and a range of services so that:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>providers can have multidisciplinary advocates or specific ones, depending on the needs of clients</p>
</li>
<li class="listitem">
<p>services tailored to the local population are made available, for example, peer advocacy, family advocacy, group advocacy, statutory advocacy and non-statutory advocacy.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_8_15" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.8.15 </span>Commissioners should ensure that the role of advocates in safeguarding is included in specifications when commissioning, developing policy and practice, and by promoting the value of advocacy in safeguarding people.</p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#planning-and-commissioning-services-for-advocacy-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#planning-and-commissioning-services-for-advocacy-2" aria-controls="planning-and-commissioning-services-for-advocacy-2" aria-expanded="false" role="button">rationale and impact section on planning and commissioning services for advocacy&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/d-improving-access-to-advocacy-pdf-11258473793" target="_top">evidence review D: improving access to advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/f-what-does-effective-advocacy-look-like-pdf-11258473795" target="_top">evidence review F: what does effective advocacy look like?</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/h-planning-and-commissioning-services-for-advocacy-pdf-11258473797" target="_top">evidence review H: planning and commissioning services for advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/i-training-skills-and-support-for-advocates-pdf-405305056404" target="_top">evidence review I: training, skills and support for advocates</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/k-monitoring-services-and-collecting-data-for-quality-improvement-pdf-405305056406" target="_top">evidence review K: monitoring services and collecting data for quality improvement</a>. </p>
</li>
</ul>
</div>
<aside id="planning-and-commissioning-services-for-advocacy-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="planning-and-commissioning-services-for-advocacy-2" type="button">Hide rationale and impact section on planning and commissioning services for advocacy&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.9 Training, skills and support for advocates" id="ng227_1.9-training-skills-and-support-for-advocates">
<h3 class="title">
<a id="training-skills-and-support-for-advocates"></a>1.9 Training, skills and support for advocates</h3>
<div id="ng227_1_9_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.1 </span>Commissioners and advocacy providers should work with public bodies and providers to increase investment in training for advocates so that they are trained and competent to support people from a variety of backgrounds and with a variety of needs. </p>
</div>
<div id="ng227_1_9_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.2 </span>Advocacy providers should ensure that training, skills development and support for advocates covers the health, social care, housing, welfare and justice processes that are relevant to their role, so they can support people to navigate these services. These could include:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>NHS continuing healthcare and other health-funded support</p>
</li>
<li class="listitem">
<p>adult social care</p>
</li>
<li class="listitem">
<p>personal budgets, personal health budgets and integrated personal budgets </p>
</li>
<li class="listitem">
<p>personal independence payments</p>
</li>
<li class="listitem">
<p>mental health services</p>
</li>
<li class="listitem">
<p>section&nbsp;117 aftercare under the Mental Health Act&nbsp;1983</p>
</li>
<li class="listitem">
<p>safeguarding procedures.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_9_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.3 </span>Advocacy services should provide training, skills development and support including induction, to their advocacy staff. Training could include: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>core advocacy principles, for example those laid out in the <a class="link" href="https://www.ndti.org.uk/resources/new-advocacy-charter" target="_top">Advocacy Charter</a></p>
</li>
<li class="listitem">
<p>
<a class="link" href="terms-used-in-this-guideline#anti-oppressive-practice" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#anti-oppressive-practice">anti-oppressive practice</a> and culturally appropriate advocacy training </p>
</li>
<li class="listitem">
<p>communication, including specialised communication skills, for example communicating with people with a learning disability</p>
</li>
<li class="listitem">
<p>identifying abuse or neglect</p>
</li>
<li class="listitem">
<p>understanding human rights and how to promote them</p>
</li>
<li class="listitem">
<p>
<a class="link" href="terms-used-in-this-guideline#health-inequalities" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#health-inequalities">health inequalities</a>
</p>
</li>
<li class="listitem">
<p>making information available to people about how to make complaints, for example about health and social care services or local authorities </p>
</li>
<li class="listitem">
<p>social skills, for example being approachable and building rapport</p>
</li>
<li class="listitem">
<p>perseverance and tenacity</p>
</li>
<li class="listitem">
<p>time management</p>
</li>
<li class="listitem">
<p>managing expectations </p>
</li>
<li class="listitem">
<p>confidence to challenge decisions</p>
</li>
<li class="listitem">
<p>consistency</p>
</li>
<li class="listitem">
<p>maintaining General Data Protection Regulation (GDPR) compliance, report writing and record keeping</p>
</li>
<li class="listitem">
<p>understanding <a class="link" href="terms-used-in-this-guideline#structural-inequalities" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#structural-inequalities">structural inequalities</a> and <a class="link" href="terms-used-in-this-guideline#intersectionality" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#intersectionality">intersectionality</a></p>
</li>
<li class="listitem">
<p>equity, diversity and inclusion.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_9_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.4 </span>Advocates should complete the National Qualification in Independent Advocacy. </p>
</div>
<div id="ng227_1_9_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.5 </span>Advocacy organisations should ensure arrangements are in place for the regular support and supervision of all advocates. </p>
</div>
<div id="ng227_1_9_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.6 </span>Training for advocacy staff should include when and how to use <a class="link" href="terms-used-in-this-guideline#non-instructed-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#non-instructed-advocacy">non-instructed advocacy</a>. </p>
</div>
<div id="ng227_1_9_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.7 </span>Consider giving advocates who deliver non-instructed advocacy increased access to support, supervision and <a class="link" href="terms-used-in-this-guideline#reflective-practice" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#reflective-practice">reflective practice</a> to ensure their advocacy remains person led, independent and outcome focused.</p>
</div>
<div id="ng227_1_9_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.9.8 </span>Advocacy services should ensure any volunteer advocates are trained and given adequate support and supervision.</p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#training-skills-and-support-for-advocates-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#training-skills-and-support-for-advocates-2" aria-controls="training-skills-and-support-for-advocates-2" aria-expanded="false" role="button">rationale and impact section on training, skills and support for advocates&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/i-training-skills-and-support-for-advocates-pdf-405305056404" target="_top">evidence review I: training, skills and support for advocates</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/j-training-and-skills-for-practitioners-who-work-with-advocates-pdf-405305056405" target="_top">evidence review J: training and skills for practitioners who work with advocates</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/k-monitoring-services-and-collecting-data-for-quality-improvement-pdf-405305056406" target="_top">evidence review K: monitoring services and collecting data for quality improvement</a>. </p>
</li>
</ul>
</div>
<aside id="training-skills-and-support-for-advocates-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="training-skills-and-support-for-advocates-2" type="button">Hide rationale and impact section on training, skills and support for advocates&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.10 Training and skills for health and social care practitioners who work with advocates" id="ng227_1.10-training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates">
<h3 class="title">
<a id="training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates"></a>1.10 Training and skills for health and social care practitioners who work with advocates</h3>
<div id="ng227_1_10_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.10.1 </span>Providers and commissioners should ensure that information about advocacy is included in training for all health and social care practitioners at induction, with refresher training every 2&nbsp;to 3&nbsp;years or as needed, so that they understand:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>what advocacy is </p>
</li>
<li class="listitem">
<p>who is entitled to advocacy support under current legislation </p>
</li>
<li class="listitem">
<p>what advocacy support services are available locally in addition to those required by law</p>
</li>
<li class="listitem">
<p>when and how to request advocacy</p>
</li>
<li class="listitem">
<p>how to facilitate advocacy </p>
</li>
<li class="listitem">
<p>the role of the advocate in different settings and situations.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_10_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.10.2 </span>Providers and commissioners should ensure that staff who may be the first point of contact for people using health and social care services that regularly work with advocacy services (for example receptionists) understand:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>who is entitled to advocacy support under current legislation </p>
</li>
<li class="listitem">
<p>what additional advocacy is available locally </p>
</li>
<li class="listitem">
<p>when and how to request advocacy.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_10_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.10.3 </span>Providers and commissioners should ensure that staff in organisations working with advocacy services (including social workers, members of Safeguarding Adults Board members and commissioners of advocacy) have training in the role and function of advocates. This includes understanding that advocates:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>help people to get the support they need from services, for example by offering to attend meetings, writing letters and emails, and making phone calls</p>
</li>
<li class="listitem">
<p>support the person to make decisions, for example by providing information about available support services, making sure people understand their options and exploring the potential outcomes of the possible options</p>
</li>
<li class="listitem">
<p>represent only the views of the person they are supporting</p>
</li>
<li class="listitem">
<p>ensure the person's voice is heard and their rights are respected in all discussions</p>
</li>
<li class="listitem">
<p>aim to empower the person to develop personal agency, <a class="link" href="terms-used-in-this-guideline#self-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#self-advocacy">self-advocacy</a> and confidence</p>
</li>
<li class="listitem">
<p>are independent of any provider service</p>
</li>
<li class="listitem">
<p>share information they receive with the person they are supporting</p>
</li>
<li class="listitem">
<p>challenge decisions and poor practice </p>
</li>
<li class="listitem">
<p>know what to do about safeguarding</p>
</li>
<li class="listitem">
<p>have a role in protecting a person's rights and promoting <a class="link" href="terms-used-in-this-guideline#wellbeing" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#wellbeing">wellbeing</a></p>
</li>
<li class="listitem">
<p>are involved in <a class="link" href="terms-used-in-this-guideline#non-instructed-advocacy" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#non-instructed-advocacy">non-instructed advocacy</a> and know what this is.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_10_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.10.4 </span>Providers of training on advocacy should:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>tailor training to practitioners' roles and responsibilities</p>
</li>
<li class="listitem">
<p>include people with lived experience of using advocacy services when designing and delivering training</p>
</li>
<li class="listitem">
<p> be able to deliver training in different formats, including face-to-face, digitally (for example, as e‑modules) and self-paced.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_10_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.10.5 </span>Health and social care providers should check that practitioners are using the knowledge and understanding of advocacy obtained through training, in their day-to-day practice, for example through supervision and <a class="link" href="terms-used-in-this-guideline#reflective-practice" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#reflective-practice">reflective practice</a>.</p>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates-2" aria-controls="training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates-2" aria-expanded="false" role="button">rationale and impact section on training and skills for health and social care practitioners who work with advocates&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/d-improving-access-to-advocacy-pdf-11258473793" target="_top">evidence review D: improving access to advocacy</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/f-what-does-effective-advocacy-look-like-pdf-11258473795" target="_top">evidence review F: what does effective advocacy look like? </a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/j-training-and-skills-for-practitioners-who-work-with-advocates-pdf-405305056405" target="_top">evidence review J: training and skills for practitioners who work with advocates</a>.</p>
</li>
</ul>
</div>
<aside id="training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="training-and-skills-for-health-and-social-care-practitioners-who-work-with-advocates-2" type="button">Hide rationale and impact section on training and skills for health and social care practitioners who work with advocates&nbsp;</button></aside></div>
</div>
</div>
<div class="section" title="1.11 Monitoring services and collecting data for quality improvement" id="ng227_1.11-monitoring-services-and-collecting-data-for-quality-improvement">
<h3 class="title">
<a id="monitoring-services-and-collecting-data-for-quality-improvement"></a>1.11 Monitoring services and collecting data for quality improvement</h3>
<div class="section" title="Agreeing outcomes" id="ng227_agreeing-outcomes">
<h4 class="title">
<a id="agreeing-outcomes"></a>Agreeing outcomes</h4>
<div id="ng227_1_11_1" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.1 </span>Advocacy service providers, commissioners, people who use advocacy services and other stakeholders should work together to agree: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>what service-level outcomes should be achieved (for example, making sure people's voices are heard, improving people's experience of safeguarding, empowerment and reducing <a class="link" href="terms-used-in-this-guideline#health-inequalities" target="_top" rel="internal" data-chapter="terms-used-in-this-guideline" data-original-url="#health-inequalities">health inequalities</a>) </p>
</li>
<li class="listitem">
<p>how these outcomes will be reported (for example, information on outcomes could be separated out based on protected characteristics or other disadvantaged groups, such as those experiencing health inequalities).</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_11_2" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.2 </span>Advocacy service providers and commissioners should work together to agree how they will record their progress against the service-level outcomes.</p>
</div>
<div id="ng227_1_11_3" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.3 </span>When monitoring advocacy services, advocacy providers and commissioners should measure outcomes that show the impact of advocacy on:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>people using an advocate (for example, to what extent they feel, or are, protected from harm, and the effects on their voice being heard, personal control and independence, their opportunities, challenging injustice and having their rights upheld)</p>
</li>
<li class="listitem">
<p>the health and care system (for example, the effects on the quality-of-service response and experience of people using it, person-led decision making and health inequalities)</p>
</li>
<li class="listitem">
<p>communities (for example, the effects on social inclusion; access to community services; and opportunities for people to contribute positively to society and get involved in their local community and engage with local forums, such as partnership boards and Safeguarding Adults Boards)</p>
</li>
<li class="listitem">
<p>the way advocacy services are run (for example, the effects on access to advocacy, governance and best practice; co‑production; and how advocacy is delivered).</p>
</li>
</ul>
</div>
</div>
</div>
<div class="section" title="What data to collect" id="ng227_what-data-to-collect">
<h4 class="title">
<a id="what-data-to-collect"></a>What data to collect</h4>
<div id="ng227_1_11_4" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.4 </span>Commissioners should ensure that measuring outcomes or monitoring activity does not compromise the independence or integrity of the advocacy provider, or individual privacy.</p>
</div>
<div id="ng227_1_11_5" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.5 </span>Advocacy providers, in partnership with commissioners, should record anonymised information on people who use advocacy services, including: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>protected characteristics in the <a class="link" href="https://www.legislation.gov.uk/ukpga/2010/15/contents" target="_top">Equality Act&nbsp;2010</a></p>
</li>
<li class="listitem">
<p>the main subject of advocacy support</p>
</li>
<li class="listitem">
<p>identified health inequalities</p>
</li>
<li class="listitem">
<p>communication need and preferences</p>
</li>
<li class="listitem">
<p>reasons for referral</p>
</li>
<li class="listitem">
<p>type of location or residence (such as urban, rural, care home or independent accommodation)</p>
</li>
<li class="listitem">
<p>whether the advocacy provided is instructed or non-instructed.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_11_6" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.6 </span>Advocacy providers should collect information about the impact of their services. Types of information include: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>survey data (such as satisfaction with the service provided)</p>
</li>
<li class="listitem">
<p>examples or short case studies describing how outcomes have changed as a result of advocacy</p>
</li>
<li class="listitem">
<p>the number of people reporting a particular outcome or the proportion of people who achieved a particular outcome</p>
</li>
<li class="listitem">
<p>detailed feedback on the experiences and views of people using advocacy services.</p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_11_7" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.7 </span>Local authorities and commissioners should monitor:</p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>whether health and social care providers are telling people about advocacy and the criteria for accessing it and take steps where there are gaps in this</p>
</li>
<li class="listitem">
<p>access to advocacy and take up of it by different populations in the local community. </p>
</li>
</ul>
</div>
</div>
<div id="ng227_1_11_8" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.8 </span>Commissioners should check that advocacy providers have a robust method of quality assurance that monitors and reports on their quality of service. </p>
</div>
</div>
<div class="section" title="How to collect data" id="ng227_how-to-collect-data">
<h4 class="title">
<a id="how-to-collect-data"></a>How to collect data</h4>
<div id="ng227_1_11_9" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.9 </span>Advocacy providers, in partnership with commissioners, should develop shared, consistent, practical and robust methods to record and collect information and data.</p>
</div>
<div id="ng227_1_11_10" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.10 </span>Advocacy providers, in partnership with commissioners, should tailor the formats and methods of seeking feedback about advocacy support to the person's communication needs and preferences.</p>
</div>
<div id="ng227_1_11_11" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.11 </span>Advocacy providers should find ways of gathering feedback that maximise the person's ability to provide that feedback anonymously and without the input of the advocacy provider.</p>
</div>
</div>
<div class="section" title="Evaluating and sharing data" id="ng227_evaluating-and-sharing-data">
<h4 class="title">
<a id="evaluating-and-sharing-data"></a>Evaluating and sharing data</h4>
<div id="ng227_1_11_12" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.12 </span>Commissioners should use the outcomes, data and information on user demographics and the impact of advocacy services to evaluate the effectiveness and quality of current advocacy services and to plan future services.</p>
</div>
<div id="ng227_1_11_13" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.13 </span>Commissioners, advocacy providers and health and social care providers should work together to evaluate data they have collected on advocacy services. They should use this to make any changes that are needed to health, social care or advocacy services so that they meet the needs of all communities within the local population, including under-represented groups, those with protected characteristics or those experiencing health or other inequalities.</p>
</div>
<div id="ng227_1_11_14" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.14 </span>Commissioners and advocacy providers should share insights and key information on common trends and themes from data they have collected on advocacy services and issues affecting people using advocacy services with relevant stakeholders. For example, health and social care providers, voluntary and community sector organisations, the Care Quality Commission, Safeguarding Adults Boards, integrated care partnerships and boards and local Healthwatch.</p>
</div>
</div>
<div class="section" title="Monitoring advocacy in safeguarding" id="ng227_monitoring-advocacy-in-safeguarding">
<h4 class="title">
<a id="monitoring-advocacy-in-safeguarding"></a>Monitoring advocacy in safeguarding</h4>
<div id="ng227_1_11_15" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.15 </span>Local authorities and commissioners should monitor how advocates are involved in supporting people experiencing safeguarding concerns.</p>
</div>
<div id="ng227_1_11_16" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.16 </span>Safeguarding Adults Boards should be assured that local authorities have auditing processes in place to monitor how people and their advocates are included in safeguarding processes.</p>
</div>
<div id="ng227_1_11_17" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.17 </span>Advocacy providers should report to Safeguarding Adults Boards on the extent to which partner organisations fulfil statutory duties for advocacy and safeguarding.</p>
</div>
</div>
<div class="section" title="Adhering to statutory duties" id="ng227_adhering-to-statutory-duties">
<h4 class="title">
<a id="adhering-to-statutory-duties"></a>Adhering to statutory duties</h4>
<div id="ng227_1_11_18" class="recommendation_text">
<p class="numbered-paragraph">
<span class="paragraph-number">1.11.18 </span>Commissioners and health and social care providers should ensure that they: </p>
<div class="itemizedlist indented">
<ul class="itemizedlist">
<li class="listitem">
<p>consistently adhere to and monitor the statutory duties to refer to and involve advocacy </p>
</li>
<li class="listitem">
<p>address failures in the duty to refer to statutory advocacy.</p>
</li>
</ul>
</div>
<div class="panel indented">
<p>For a short explanation of why the committee made these recommendations and how they might affect practice or services, see the <a class="link" href="rationale-and-impact#monitoring-services-and-collecting-data-for-quality-improvement-2" target="_top" rel="internal" data-chapter="rationale-and-impact" data-original-url="#monitoring-services-and-collecting-data-for-quality-improvement-2" aria-controls="monitoring-services-and-collecting-data-for-quality-improvement-2" aria-expanded="false" role="button">rationale and impact section on monitoring services and collecting data for quality improvement&nbsp;<span class="icon icon-chevron-down" aria-hidden="true"></span></a>.</p>
<p>Full details of the evidence and the committee's discussion are in: </p>
<div class="itemizedlist">
<ul class="itemizedlist">
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/f-what-does-effective-advocacy-look-like-pdf-11258473795" target="_top">evidence review F: what does effective advocacy look like?</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/g-partnership-working-and-relationships-with-families-and-carers-commissioners-and-providers-pdf-11258473796" target="_top">evidence review G: partnership working and relationships with families and carers, commissioners and providers</a>
</p>
</li>
<li class="listitem">
<p>
<a class="link" href="https://www.nice.org.uk/guidance/ng227/evidence/k-monitoring-services-and-collecting-data-for-quality-improvement-pdf-405305056406" target="_top">evidence review K: monitoring services and collecting data for quality improvement</a>.</p>
</li>
</ul>
</div>
<aside id="monitoring-services-and-collecting-data-for-quality-improvement-2" aria-hidden="true" aria-live="polite" aria-label="Rationale and impact" aria-busy="false" tabindex="-1" class="expandable"><div class="loader" aria-hidden="true"><span class="icon-spinner icon-spin" aria-hidden="true"></span><p>Loading. Please wait.</p></div><button class="btn" aria-expanded="true" aria-controls="monitoring-services-and-collecting-data-for-quality-improvement-2" type="button">Hide rationale and impact section on monitoring services and collecting data for quality improvement&nbsp;</button></aside></div>
</div>
</div>
</div>
</div>
</div>
<div class="product-footer">


</div>


<div class="guidance_logos">
<ul class="row">
<li class="span4">
<img src="/guidance/NG227/accreditationlogo/National Institute for Health and Care Excellence (NICE)?logotype=Guidance" alt="National Institute for Health and Care Excellence (NICE)">
</li>
</ul>
</div>

<br>


<div class="section-footer">
<ul class="pagination  ">
<li class="sectionmenu hidden-desktop hidden-tablet span8"><!--responsive menu inserted here--></li>
<li class="previous"><a href="/guidance/ng227/chapter/Context" aria-label="Previous chapter"><span class="icon-nice-chevron-left"></span></a></li>
<li class="next"><a href="/guidance/ng227/chapter/Terms-used-in-this-guideline" aria-label="Next chapter"><span class="hidden-phone">Next </span><span class="icon-nice-chevron-right"></span></a></li>
</ul>
</div>
</div>`;

const content = {
  html,
}

export default content;