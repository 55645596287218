import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useNavigate, Navigate } from 'react-router-dom';
import { Toaster } from 'sonner';

import AuthView from './auth';
import TopicsView from './topics';
import TopicsTreeView from './topics-tree';
import TopicView from './topic';
import AppView from './app';
import SignOutView from './sign-out';

import NiceView from './nice';

import Navbar from 'ui/navbar';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'styles/global.css';

const main = async() => {
    const existingConfig = Amplify.getConfig();
    Amplify.configure({
        ...existingConfig, 
        Auth: {
            Cognito: {
                userPoolId: 'eu-west-2_fTsRXlf73',
                userPoolClientId: '3vhspjlot2vvb8splaeih4r958',
                region: 'eu-west-2',
            },
        },
        // Logger: {
        //     LOG_LEVEL: 'DEBUG',
        // },
        // API: {
        //     ...existingConfig.API,
        //     REST: {
        //         ...existingConfig.API?.REST,
        //         headers: async() => { 
        //             const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        //             return { Authorization: authToken }; 
        //         },
        //         'v1': {
        //             endpoint: 'https://afgjpdw2r1.execute-api.eu-west-2.amazonaws.com/v1',
        //             region: 'eu-west-2',
        //             // custom_header: async () => { return { Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`}; }, 
        //         }
        //     },
        // },
    });
}



export default function RootView() {

    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [signedIn, setSignedIn] = useState(false);

    const init = async() => {
        setSignedIn(false);

        await main();

        try {
            const { username, userId, signInDetails } = await getCurrentUser();
            if(username) {
                setSignedIn(true);
            }
        }
        catch(error) {

        }

        setLoaded(true);
    }

    useEffect(()=>{ 
        const listener = Hub.listen('auth', (data) => {
            const { payload, } = data;
            const { event } = payload;
            if(event === 'signedIn') { setSignedIn(true); navigate('/topics'); }
            if(event === 'signedOut') { setSignedIn(false); }
        });

        init(); 

        return listener;
    }, []);

    if(!loaded) {
        return (
            <div>
                Loading...
            </div>
        );
    }

    if(!signedIn) {
        return (
            <Routes>
                <Route path='/signout' element={<SignOutView />}></Route>
                <Route path="*" element={
                    <Container fluid>
                        <Row>
                            <Col sm="2"></Col>
                            <Col sm="8">
                                <h2 align='center'>FYI Doctor CMS 🩺</h2>
                                <br />
                                <AuthView signUp={{visible:false}} />
                            </Col>
                            <Col sm="2"></Col>
                        </Row>
                    </Container>
                    } />
            </Routes>
        );
    }

    return (
        <>
        <Toaster position="bottom-right" duration={5000} />
        <Navbar navItems={[{title:'Topics', to:'/topics'}, {title:'App', to:'/app'}, {title:'Sign Out', to:'/signout'}]} signedIn={signedIn} brandName={'FYID'}/>
        <Container fluid>
            <div className="content-container">
                <Routes>
                    <Route path='/signout' element={<SignOutView />}></Route>
                    <Route path="/topics/*" element={<TopicsTreeView />} />
                    <Route path="/topic/:topicId" element={<TopicView />} />
                    <Route path="/app/*" element={<AppView />} />
                    <Route path="/nice/*" element={<NiceView />} />
                    <Route path="/" element={<Navigate to="/topics" />} />
                </Routes>
            </div>
        </Container>
        </>
    );
}